import React from 'react'
import { ProductComponent, ProductDescription, ProductImage, ProductImageBackground, ProductInfo, ProductName, ProductPrice, ProductNotAvailable } from './style'
import propTypes from 'prop-types';
import formatNumber from '../../helpers/formatNumber';
import truncateWithDots from '../../helpers/truncateWithDots';
import { useTranslation } from 'react-i18next';

export default function Product(props) {
    const { t } = useTranslation();
    const { imageUrl: image, name, descriptionProduct: description, salePrice: price, stock } = props;

    return (
        <ProductComponent>
            <ProductImageBackground >
                <ProductImage src={image[0]} />
            </ProductImageBackground>
            <ProductInfo>
                <div>
                    <ProductName>{name}</ProductName>
                    <ProductDescription>{truncateWithDots(description, 15)}</ProductDescription>
                </div>
                {
                    stock === 0 ?
                        <ProductNotAvailable>{t('products.notAvailable')}</ProductNotAvailable>
                        :
                        <ProductPrice>{formatNumber(price)}</ProductPrice>
                }
            </ProductInfo>
        </ProductComponent>
    )
}

Product.propTypes = {
    image: propTypes.string,
    name: propTypes.string,
    description: propTypes.string,
    price: propTypes.string,
}