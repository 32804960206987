import styled from "styled-components";

export const ProductComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 118px;
  position: relative;
  margin-bottom: 2px;
`;
export const ProductImageBackground = styled.div`
  background-color: ${(prop) => prop.theme.colors.grayLight};
  height: 125px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ProductNotAvailable = styled.div`
  display: inline-block;
  font-size: 12px;
  text-align: center;
  background: ${(prop) => prop.theme.colors.gray};
  color: #fff;
  border-radius: 15px;
  padding: 2px 10px;
  font-weight: bold;
  margin: 5px 0px;
`;

export const ProductImage = styled.img`
  height: 100px;
  width: 100px;
`;

export const ProductInfo = styled.div`
  background-color: ${(prop) => prop.theme.colors.graySuperLight};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2px 5px;
  height: 65px;
`;

export const ProductName = styled.div`
  font-size: 14px;
  font-weight: bold;
  width: 110px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ProductDescription = styled.div`
  font-size: 12px;
  margin-top: 3px;
  width: 110px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ProductPrice = styled.div`
  font-size: 14px;
  align-self: flex-end;
  font-weight: bold;
  margin-top: 5px;
`;
