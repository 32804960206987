import React, { useEffect, useState } from "react";
import { HomeHeader } from "./HomeHeader";
import { HomePage } from "./HomePage";
import { HomeContainer } from "./style";
import { useCart } from '../../context/cart';
import { useParams } from 'react-router';
import { useTranslation } from "react-i18next";
import { EditAlert } from '../../../src/pages/ProductDetail/AddOrEditAlert/Edit';

export const Home = () => {
  const { cart } = useCart();
  const [showInfo, setShowInfo] = useState(false);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const { clientId } = useParams();

  useEffect(() => {
    if (cart.id === null || cart.id === undefined || cart.statecart === 'Cancelado') {
      sessionStorage.removeItem('cart');
      setShow(clientId !== 'demo');
    } else {
      setShow(false);
    }
  }, [cart.id, cart.statecart, clientId])

  return (
    <HomeContainer>
      {show === false ?
        <>
          <HomeHeader
            showInfo={showInfo}
            setShowInfo={(value) => setShowInfo(value)}
          />
          <HomePage showInfo={showInfo} />
        </>
        :
        <EditAlert show={show} message={t('detail.cartError')} />
      }
    </HomeContainer>
  );
};

export default Home;
