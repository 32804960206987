import styled from "styled-components";

export const AddressItemContainer = styled.div` 
    background-color: white;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 22px;
    border-top: 1px solid ${prop => prop.theme.colors.gray};
    display: flex;
    align-items: center;
    justify-content: space-between;

`
export const AddressItemInfo = styled.div` 
    display: flex;
    flex-direction: column;
    min-height: 80px;
`
export const AddressItemName = styled.div` 
    font-size: 22px;
    color: black;
`
export const AddressItemDescription = styled.div` 
    font-size: 14px;
    color: ${prop => prop.theme.colors.grayMedium};
    margin-top: 10px;
`
export const AddressItemActions = styled.div` 
    display: flex;
    gap: 10px;
    margin-left: 10px;
`
export const AddressItemButton = styled.button` 
    outline: none;
    border: none;
    font-size: 20px;
    padding: 8px 10px;
    display: flex;
    border-radius: 4px;
    background: ${prop => prop.theme.colors.grayBackground};
`
