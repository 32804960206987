import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { getChannel } from '../../services/getChannel';
import Modal from "../../components/Modal";
import { Channel } from '../../components/Channel';
import { DeniedModalContainer, ProductInfo, DeniedOptions, DeniedModalBody, DeniedInfo } from './style';
import { useParams } from 'react-router';
import { useCart } from '../../context/cart';

export default function ChannelModal(props) {
    const { storeId } = useParams();
    const { cart } = useCart();
    const [channels, setChannels] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        getChannel(storeId)
            .then(setChannels);
    }, [storeId, cart]);

    return <Modal show={true}>
        <DeniedModalContainer>
            <DeniedModalBody>
                <DeniedInfo>
                {t('cart.denied')}
                </DeniedInfo>
                <ProductInfo>
                    {t('cart.channel')}
                </ProductInfo>
                <DeniedOptions>
                    {
                        channels.map(channel => <Channel
                            key={channel._id}
                            type={channel.channelType.key}
                            name={channel.channelType.name}
                            extras={channel.extras}
                        />)
                    }
                </DeniedOptions>
            </DeniedModalBody>
        </DeniedModalContainer>
    </Modal>
}

ChannelModal.propTypes = {
    show: propTypes.bool,
    onClose: propTypes.func,
}