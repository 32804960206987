import styled from "styled-components";

export const CartContainer = styled.div` 
    background-color: ${prop => prop.theme.colors.background};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`
export const CartBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px 20px 0px;
`
export const CartHelpInfo = styled.div`
    background-color: white;
    font-size: 14px;
    color: ${prop => prop.theme.colors.secondary};
    padding: 10px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    gap: 5px;
`
export const CartHelpIcon = styled.div`
    font-size: 20px;
    margin-top: 3px;
`
export const CartOptions = styled.div`
    display: flex;
    flex: 1; 
    flex-direction: column;
    justify-content: space-between;
`
export const CartContent = styled.div` 
    display: flex;
    flex-direction: column;
    height: 200px;
    overflow-y: scroll;
    background-color: white;
    border: 1px solid ${prop => prop.theme.colors.gray};

    ::-webkit-scrollbar {
        display: none;
    }
`

export const EmptyCartContainer = styled.div`
    flex: 1; 
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: calc(100vh - 60px);
`
export const EmptyCartBody = styled.div`
    flex: 1; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: white;
`


export const EmptyCartIcon = styled.div`
    font-size: 125px;
    color: ${prop => prop.theme.colors.grayDark};
`
export const EmptyCartText = styled.div`
    font-size: 24px;
    font-weight: bold;
    color: black;
`
export const EmptyCartAdd = styled.div`
    font-size: 20px;
    color: ${prop => prop.theme.colors.gray};
    margin-bottom: 20px;
`






