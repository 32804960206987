import styled, { css } from "styled-components";

export const TextareaContainer = styled.div` 
    display: flex;
    flex-direction: column;
    gap: 8px;
`
export const TextareaWrapper = styled.div` 
    -webkit-appearance: none;
    display: inline-flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: ${prop => prop.theme.colors.grayBackground};
    border: 1px solid ${prop => prop.theme.colors.grayDark};
    width: 100%;
    overflow: hidden;
    transition: border-color ease 0.5s;

    &:focus-within {
        border-color: ${prop => prop.theme.colors.secondary};
    }

    ${({ error }) => error && css`
         border-color: ${prop => prop.theme.colors.red};

         &:focus-within {
            border-color: ${prop => prop.theme.colors.red};
        }
      `
    }
`
export const TextareaComponent = styled.textarea`
    border: none;
    outline: none;
    resize: none;
    padding: 12px 10px;
    background-color: white;
    font-size: 16px;
    width: ${props => props.width};
    max-width: ${props => props.maxWidth};
    transition: all 0.5s;

    ::placeholder {
        color: black;
        opacity: 0.5;
    }

`
export const TextareaLabel = styled.label`
    font-size: 16px;
    color: black;
    font-weight: bold;
`
