import styled from "styled-components";

export const AddressesComponent = styled.div` 
    display: flex;
    flex-direction: column;
    background-color: ${prop => prop.theme.colors.background};
    padding: 20px;
    padding-bottom: 0px;
    height: 100%;
`
export const AddressesBody = styled.div` 
    flex: 1;
    background-color: white;
`
export const AddressesAdd = styled.div` 
   color: ${prop => prop.theme.colors.secondary};
   border-top: 1px solid ${prop => prop.theme.colors.gray};
   padding: 20px 10px;
`