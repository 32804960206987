import axios from 'axios';
import { productBackToFront } from '../adapters/product';

export const getCart = async (storeId, clientId) => {
    const url = `${process.env.REACT_APP_TEST_URL}/marketplace/sale/getCart`;

    const body = { campaign: storeId, idClient: clientId }
    const { data } = await axios.post(url, body);

    return data?.car ? { ...data, car: data.car.map(productBackToFront) } : data;
}
