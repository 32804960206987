import styled from "styled-components";
import { HeaderComponent } from "../Header/style";

export const CategoryHeader = styled(HeaderComponent)`
  display: flex;
  justify-content: center;
  height: 50px;
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
`;
export const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px 20px;
`;

export const CategoryName = styled.div`
  color: white;
  font-size: 16px;
  font-weight: bold;
  z-index: 2;
`;

export const OverlayHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  color: #fff;
  background: rgba(2, 30, 81, 0.4);
  z-index: 1;
`;
