export const theme = {
    colors: {
        primary: "#021e51",
        secondary: "#2255ff",
        accent: "#0e0d13",
        white: "#ffffff",
        gray: "#a4aaba",
        grayLight: "#E3E3E3",
        graySuperLight: "#edeff6",
        grayMedium: "#8D8D8D",
        grayDark: "#949bb1",
        grayBackground: "#EFF0F2",
        grayBubble: "#eceef1",
        background: "#F7F7F7",
        alertRed: "#d0242b",
        red: "#FF645A",
        green: "#02CD6B",
        blueLight: "#2CD2FF"
    }
};