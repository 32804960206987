import { motion } from "framer-motion";
import styled from "styled-components";

export const SearchOverlayContainer = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  padding: 50px 0px;
`;

export const ResultsContainer = styled.div`
  margin-top: 10px;
  height: 100%;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const EmptyResults = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 120px);
  font-size: 40px;
`;
