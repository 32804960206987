import React, { useState } from "react";
import { bool, func, number, string } from "prop-types";
import { BsChevronRight } from 'react-icons/bs';
import { ProductFooter, Stock } from "../style";
import { useCart } from "../../../context/cart";
import { useParams } from "react-router";
import { ProductInput, ProductNameContainer, ProductName, PriceName, ProductStock } from './style';
import NoProductFooter from "../NoProductFooter";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/Footer";
import Button from "../../../components/Button";
import ProductModal from '../ProductModal/index.js';

export default function ProductDetailFooter({
  stock,
  quantity,
  onClick,
  buttonTitle,
  totalQuantityEdit,
  disabledButton,
  inputValue,
  handleChangeItem,
  setInputValue,
  wholesalePriceStatus,
  wholesalePriceStatusEdit,
  wholesalePrice,
}) {
  const { t } = useTranslation();
  const { cart } = useCart();
  const { productId } = useParams();
  const [showModal, setShowModal] = useState(false);

  const cartQuantity = cart?.products
    ? cart?.products?.map((product) => {
        return {
          id: product.id,
          quantity: product.quantity,
        };
      })
    : [];

  const cartProduct = cartQuantity?.filter((e) => productId === e.id);
  let totalQ = cartProduct.reduce((total, { quantity }) => total + quantity, 0);

  let totalQuantity = stock - totalQ - quantity;

  const totals = () => {
    if (totalQuantity) {
      return totalQuantity;
    }
    return totalQuantityEdit;
  };

  return (
    <>
      {stock === 0 || totalQuantity === -1 ? (
        <NoProductFooter />
      ) : (
        <>
          <ProductFooter
            maxStock={
              quantity === stock ||
              totalQuantity === 0 ||
              totalQuantityEdit === 0
            }
          >
            <ProductInput>
              <ProductNameContainer>
                <ProductName>
                  {t("products.quantity")}  
                  <PriceName>{inputValue}</PriceName>
                  {totalQuantityEdit > 0 ?
                    <ProductStock>{t("products.existence", { stock: totalQuantityEdit })}</ProductStock>
                  :
                    <ProductStock>{t("products.existence", { stock: totalQuantity })}</ProductStock>
                  }
                </ProductName>
              </ProductNameContainer>
              <BsChevronRight onClick={() => { setShowModal(true); }} />
              {showModal &&
                <ProductModal
                  stock={stock}
                  wholesalePriceStatus={wholesalePriceStatus}
                  wholesalePrice={wholesalePrice}
                  show={showModal}
                  onClose={() => setShowModal(false) }
                  inputValue={inputValue}
                  quantity={quantity}
                  handleChangeItem={handleChangeItem}
                  setInputValue={setInputValue}
                  totalQuantity={totals()}
                  wholesalePriceStatusEdit={wholesalePriceStatusEdit}
                />
              }
            </ProductInput>
            {quantity === stock && (
              <Stock>{t("products.existence", { stock })}</Stock>
            )}
            {(totalQuantity === 0 || totalQuantityEdit === 0) && (
              <Stock>{t("products.existence-cart", { totalQuantity })}</Stock>
            )}
          </ProductFooter>
          <Footer>
            <Button
              title={buttonTitle}
              disabled={disabledButton}
              onClick={onClick}
            />
          </Footer>
        </>
      )}
    </>
  );
}

ProductDetailFooter.propTypes = {
  stock: number,
  quantity: number,
  inputValue: number,
  handleChangeItem: func,
  setInputValue: func,
  onDelete: func,
  onClick: func,
  buttonTitle: string,
  totalQuantityEdit: number,
  wholesalePriceStatusEdit: bool,
  disabledButton: bool,
};
