import React from "react";
import { HomeHeaderComponent, OverlayHeader } from "../style";
import { func, bool } from "prop-types";
import { StoreInfo } from "../StoreInfo";
import { StoreInfoModal } from "../StoreInfoModal";
import { useShop } from "../../../context/shop";

export const HomeHeader = ({ showInfo, setShowInfo }) => {
  const { shop } = useShop();

  return (
    <HomeHeaderComponent img={shop?.backgroundUrl}>
      <StoreInfo store={shop} onClick={() => setShowInfo(true)} />
      {showInfo && (
        <StoreInfoModal
          store={shop}
          show={showInfo}
          onClose={() => setShowInfo(false)}
        />
      )}
      <OverlayHeader />
    </HomeHeaderComponent>
  );
};

HomeHeader.propTypes = {
  showInfo: bool,
  setShowInfo: func,
};
