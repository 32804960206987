import styled from "styled-components";
import { ButtonComponent } from "../../../components/Button/style";


export const OrderItemContainer = styled.div` 
    background-color: white;
    padding: 10px;
    font-weight: 500;
    font-size: 22px;
    border-top: 1px solid ${prop => prop.theme.colors.gray};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    &:last-child {
        border-bottom: 1px solid ${prop => prop.theme.colors.gray};
    }
`

export const OrderItemImage = styled.img` 
    background-image: url(${props => props.image});
    height: 70px;
    width: 70px;
`

export const OrderItemInfo = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;
`
export const OrderName = styled.div` 
    font-size: 16px;
    font-weight: bold;
`
export const OrderDescription = styled.div` 
    font-size: 14px;
    color: ${prop => prop.theme.colors.gray};
`
export const OrderData = styled.div` 
    display: flex;
    gap: 10px;
    color: black;
    font-size: 14px;
`
export const OrderButton = styled(ButtonComponent)` 
    font-size: 14px;
    border-radius: 4px;
    padding: 10px 8px;
    margin-left: 10px;
`