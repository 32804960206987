import styled from "styled-components";

export const CartModalContainer = styled.div` 
    background-color: white;
    border-radius: 4px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`

export const CancelTitle = styled.div` 
    font-size: 22px;
    font-weight: bold;
`

export const CancelDescription = styled.div` 
    font-size: 18px;
    color: ${prop => prop.theme.colors.gray}
`

export const CancelButtons = styled.div` 
    display: flex;
    gap: 20px;
    margin-top: 15px;
`