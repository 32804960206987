import React, { useEffect, useState } from "react";
import Link from "../../../components/Link";
import propTypes from "prop-types";
import { SubcategoriesBody, Subcategory } from "../style";
import truncateWithDots from "../../../helpers/truncateWithDots";
import { useTranslation } from "react-i18next";
import { useRouteNavigation } from "../../../hooks/useRouteNavigation";

export const SubcategoriesList = ({ subcategories, categoryId }) => {
  const { t } = useTranslation();
  const goToRoute = useRouteNavigation();
  const [formattedSubcategories, setFormattedSubcategories] = useState([]);

  // Adds three dots if category name is too long
  useEffect(() => {
    const format = subcategories?.map((subcategory) => {
      return {
        ...subcategory,
        name:
          subcategory.name.length > 45
            ? truncateWithDots(subcategory.name, 42)
            : subcategory.name,
      };
    });

    setFormattedSubcategories(format);
  }, [subcategories]);

  const changeRoute = (subcategory) => {
    if (subcategory.childrens?.length > 0) {
      // Shows the subcategories page
      goToRoute(`/category/${subcategory.id}`);
    } else {
      // Shows the products of a selected category
      goToRoute(`/category/${subcategory.id}/products`);
    }
  };
  return (
    <SubcategoriesBody multiple={subcategories?.length > 0}>
      <Subcategory>
        <Link to={`/category/${categoryId}/all`}>
          {t("categories.allProducts")}
        </Link>
      </Subcategory>
      {formattedSubcategories?.filter(subcategory => subcategory.active).map((subcategory) => {
          if (subcategory.products.length > 0 || subcategory.childrens.length > 0) {
            return  <Subcategory
              key={subcategory.name}
              onClick={() => changeRoute(subcategory)}
            >
              {subcategory.name}
            </Subcategory>
          }
          return false;
      })}
    </SubcategoriesBody>
  );
};

SubcategoriesList.propTypes = {
  subcategories: propTypes.array,
  categoryId: propTypes.string,
};
