import styled from "styled-components";

export const SearchItem = styled.div`
  background-color: ${(prop) => prop.theme.colors.graySuperLight};
  background-color: white;
  padding: 20px;
  height: 30px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(prop) => prop.theme.colors.graySuperLight};
  font-weight: bold;
  font-size: 14px;
  color: ${(prop) => prop.theme.colors.primary};
`;

export const SearchItemImage = styled.img`
  height: 60px;
  width: 60px;
  margin-right: 20px;
`;
