import styled from "styled-components";

export const FooterComponent = styled.div` 
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    border-top: 1px solid ${prop => prop.theme.colors.graySuperLight};
    padding: 0px 20px;
`
