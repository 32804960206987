import propTypes from 'prop-types';
import React from 'react';
import { PaymentMethodDetail, PaymentMethodIcon, PaymentMethodItem, PaymentSelectedIcon, PaymentTitle, PayImage, PayDiv } from '../style';
import { BsCheck2 } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import cash from '../../../assets/images/payments/cash.svg';
import conekta from '../../../assets/images/payments/conekta.svg';
import mercado from '../../../assets/images/payments/mercado_pago.svg';


export default function PaymentMethod(props) {
    const { t } = useTranslation();
    const images = {
        'cash': cash,
        'conekta': conekta,
        'mercado-pago': mercado,
    }
    return <PaymentMethodItem onClick={props.onClick}>
        <PaymentMethodDetail>
            <PayDiv>
                <PaymentMethodIcon>
                    <PayImage src={images[props.method] } />
                </PaymentMethodIcon>
                <PaymentTitle>
                    {props.cash ? t('payment.cash') : t('payment.card')}
                </PaymentTitle>
            </PayDiv>
        </PaymentMethodDetail>
        {props.selected && <PaymentSelectedIcon >
            <BsCheck2 />
        </PaymentSelectedIcon>}
    </PaymentMethodItem>;
}

PaymentMethod.propTypes = {
    method: propTypes.string,
    cash: propTypes.string,
    cardNumber: propTypes.string,
    selected: propTypes.bool,
    onClick: propTypes.func,
}
