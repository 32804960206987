import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { getChannel } from '../../../services/getChannel';
import Modal from "../../../components/Modal";
import { Channel } from '../../../components/Channel';
import { CartModalContainer, ProductInfo, ProductOptions, CartModalButton, CartModalBody } from './style';
import { IoMdClose } from 'react-icons/io';
import { useParams } from 'react-router';
import { useCart } from '../../../context/cart';

export default function ChannelModal(props) {
    const { storeId } = useParams();
    const { cart } = useCart();
    const [channels, setChannels] = useState([]);
    const { t } = useTranslation();
    const { show, onClose } = props;

    useEffect(() => {
        if (cart.clientId === 'qrcode') {
            getChannel(storeId)
                .then(setChannels);
        }
    }, [storeId, cart]);

    return <Modal show={show}>
        <CartModalContainer>
            <CartModalButton onClick={onClose}><IoMdClose /></CartModalButton>
            <CartModalBody>
                <ProductInfo>
                    {t('cart.channel')}
                </ProductInfo>
                <ProductOptions>
                    {
                        channels.map(channel => <Channel
                            key={channel._id}
                            type={channel.channelType.key}
                            name={channel.channelType.name}
                            extras={channel.extras}
                        />)
                    }
                </ProductOptions>
            </CartModalBody>
        </CartModalContainer>
    </Modal>
}

ChannelModal.propTypes = {
    show: propTypes.bool,
    onClose: propTypes.func,
}