import styled from "styled-components";

export const CardComponent = styled.div` 
    background-color: white;
    border: 1px solid  ${prop => prop.theme.colors.graySuperLight};
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    position: relative;
`
