import React, { createContext, useCallback, useContext } from "react";
import { useState } from "react";
import { addProduct } from "../services/sales/addProduct";
import { editProduct } from "../services/sales/editProduct";
import { getCart } from "../services/sales/getCart";
import { deleteProduct } from "../services/sales/deleteProduct";
import { deleteCart } from "../services/sales/deleteCart";
import getProductTotal from "../helpers/getProductTotal";
import { useAddress } from "./address";
import { useShop } from "./shop";

export const CartContext = createContext(null);

/**
 * Product type definition
 * @typedef {Object} Product
 * @property {string} id
 * @property {string} name
 * @property {number} price
 * @property {number} quantity
 * @property {string} lineId
 * @property {array} optionsGroup
 */

export default function CartProvider({ children }) {
  const [cart, setCart] = useState({
    id: null,
    channelId: null,
    clientName: null,
    clientUsername: null,
    products: [],
    deliveryAmount: 0,
    statecart: '',
  });
  const [deliveryAmount, setDeliveryAmount] = useState(0);
  const { deliveryOption } = useShop();
  const [delivery] = deliveryOption;
  const { deliveryAddress } = useAddress();
  const [address] = deliveryAddress;
  const initValue =
    !delivery || (delivery && !address.lat) ? 0 : deliveryAmount;

  const subtotal = cart.products?.reduce((total, product) => {
    return total + getProductTotal(product);
  }, 0);

  const total = cart.products?.reduce((total, product) => {
    return total + getProductTotal(product);
  }, initValue);

  const loadCart = useCallback(
    async (storeId, clientId) => {
      let cart = JSON.parse(sessionStorage.getItem("cart"));
      if (!cart) {
        cart = await getCart(storeId, clientId);
      }

      setCart({
        id: cart?._id,
        channelId: cart?.qr ? "qrcode" : cart?.channel,
        clientId: clientId,
        clientName: cart?.client?.name,
        clientUsername: cart?.client?.username,
        products: cart?.car,
        statecart: cart?.statecart?.name,
      });
    },
    [setCart]
  );

  const store = {
    cart: cart,
    subtotal: subtotal,
    total: total,
    loadCart: loadCart,
    deliveryAmount: [deliveryAmount, setDeliveryAmount],
    addProduct: async (product) => {
      let sale;

      sale = await addProduct({ saleId: cart.id, products: [product] });

      setCart((prevCart) => {
        return {
          id: sale._id,
          channelId: prevCart.channelId,
          clientId: prevCart.clientId,
          clientName: prevCart.clientName,
          clientUsername: prevCart.clientUsername,
          products: sale.car,
        };
      });
      sessionStorage.setItem("cart", JSON.stringify(sale));
    },
    editProduct: async (product) => {
      let sale;

      sale = await editProduct({ saleId: cart.id, products: [product] });

      setCart((prevCart) => {
        return {
          id: sale._id,
          channelId: prevCart.channelId,
          clientId: prevCart.clientId,
          clientName: prevCart.clientName,
          clientUsername: prevCart.clientUsername,
          products: sale.car,
        };
      });
      sessionStorage.setItem("cart", JSON.stringify(sale));
    },
    deleteProduct: async (lineId) => {
      const sale = await deleteProduct({
        cartId: cart.id,
        products: cart.products.filter((product) => product.lineId === lineId),
      });
      setCart((prevCart) => {
        return {
          id: sale._id,
          channelId: prevCart.channelId,
          clientId: prevCart.clientId,
          clientName: prevCart.clientName,
          clientUsername: prevCart.clientUsername,
          products: sale.car,
        };
      });
      sessionStorage.setItem("cart", JSON.stringify(sale));
    },
    resetCart: async () => {
      await deleteCart(cart.id);

      setCart(() => {
        return {
          id: null,
          channelId: null,
          clientId: null,
          clientName: null,
          clientUsername: null,
          products: [],
          statecart: null,
        };
      });

      sessionStorage.removeItem("cart");
    },
  };

  return <CartContext.Provider value={store}>{children}</CartContext.Provider>;
};

export const useCart = () => {
  return useContext(CartContext);
};
