import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

export const useRouteNavigation = () => {
    const navigate = useNavigate();
    const { storeId, clientId } = useParams();


    const goToRoute = useCallback((...args) => {
        navigate(`/${storeId}/${clientId}${args[0]}`, args[1]);
    }, [storeId, clientId, navigate]);

    return goToRoute;
}