import axios from 'axios';

export const makeTransaction = async (token, user, cartId, paymentMethod) => {
    const url = `${process.env.REACT_APP_TEST_URL}/transactions/`;

    const info = { token, user, carId: cartId, paymentMethod };

    if (paymentMethod === 'mercado-pago') {
        const { response } = (await axios.post(url, info)).data;
        return response;
    }
    
    const { data } = await axios.post(url, info);
    return data;
}
