import React from 'react';
import { useTranslation } from 'react-i18next';
import computer from '../../assets/images/w.svg';
import logo from '../../assets/images/SEL_logo_franchise.svg';
import { ComputerImage, InvalidDeviceContainer, InvalidDeviceTitle, InvalidDeviceSubtitle, InvalidDeviceText, Logo } from './style';
import { Body, LayoutContainer } from '../../layouts/Main/style';

export default function InvalidDevice() {
    const { t } = useTranslation();

    return (<LayoutContainer>
        <Body>
            <InvalidDeviceContainer>
                <ComputerImage src={computer} />
                <InvalidDeviceText>
                    <InvalidDeviceTitle>{t('device.sorry')}</InvalidDeviceTitle>
                    <InvalidDeviceSubtitle>{t('device.compatibility')}</InvalidDeviceSubtitle>
                </InvalidDeviceText>
                <Logo src={logo} alt="logo" />
            </InvalidDeviceContainer>
        </Body>
    </LayoutContainer>
    )
}
