// Menu.js
import React, { useEffect, useState } from "react";
import { MenuContainer, StoreAddress, StyledMenu } from "./style";
import { bool, func } from "prop-types";
import { getCategories } from "../../services/categories/getCategories";
import findCategoryById from "../../helpers/categories/findCategoryById";
import { useParams } from "react-router";
import { MdLocationPin } from "react-icons/md";
import { useShop } from "../../context/shop";
import CategoriesList from "../CategoriesList";
import SubcategoriesList from "../SubcategoriesList";
import { AnimatePresence, motion } from "framer-motion";
import { useRouteNavigation } from "../../hooks/useRouteNavigation";

const Menu = ({ open, setOpen }) => {
  const { storeId } = useParams();
  const { shop } = useShop();
  const goToRoute = useRouteNavigation();
  const [categories, setCategories] = useState([]);
  // sets category level displaying on the list
  const [categoryLevel, setCategoryLevel] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    getCategories(storeId).then((categories) => {
      setCategories(categories);
      setCategoryLevel(categories);
    });
  }, [storeId]);

  const changeLevel = (category) => {
    if (category.childrens.length === 0) {
      setOpen(false);
      goToRoute(`/category/${category.id}/all`);
    } else {
      setSelectedCategory(category);
      setCategoryLevel(category.childrens);
    }
  };
  const goToPreviousLevel = (category) => {
    const parent = findCategoryById(categories, category.idParent);

    if (parent) {
      changeLevel(parent);
    } else {
      setSelectedCategory(null);
      setCategoryLevel(categories);
    }
  };
  const showAllProducts = (category) => {
    setOpen(false);
    goToRoute(`/category/${category.id}/all`);
  };

  const isFirstLevel = () => {
    return categoryLevel[0]?.idParent ? false : true;
  };

  const catExists = categories?.filter(category => category.active);

  const catChildren = categoryLevel?.filter(subcategory => {
    if (subcategory.active) {
      if (subcategory.products.length > 0 || subcategory.childrens.length > 0) {
        return subcategory 
      }
    }
    return false;
  });
  return (
    <StyledMenu open={open}>
      <MenuContainer>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0, x: 150 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -150 }}
            transition={{
              default: { duration: 0.25 },
            }}
            key={selectedCategory ? selectedCategory.id : "first-level"}
          >
            {isFirstLevel() ? (
              <CategoriesList categories={catExists} onClick={changeLevel} />
            ) : (
              <SubcategoriesList
                selectedCategory={selectedCategory}
                subcategories={catChildren}
                onSelectPrevious={goToPreviousLevel}
                onClick={changeLevel}
                onShowAll={showAllProducts}
              />
            )}
          </motion.div>
        </AnimatePresence>
        {shop?._id && (
          <StoreAddress>
            <MdLocationPin /> {shop.address}
          </StoreAddress>
        )}
      </MenuContainer>
    </StyledMenu>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
  setOpen: func,
};

export default Menu;
