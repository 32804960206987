const getProductTotal = (product) => {

    const optionsTotal = product.optionsGroup.reduce((total, group) => {
        return total + group.options.reduce((total, option) => { return total + option.salePrice }, 0);
    }, 0);

    return ((optionsTotal + product.price) * product.quantity);
}

export default getProductTotal;
