import styled from "styled-components";
import { CardComponent } from "../../components/Card/style";
import { HeaderComponent } from "../../components/Header/style";

export const HomeContainer = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;  
`

export const HomeHeaderComponent = styled(HeaderComponent)` 
    display: flex;
    justify-content: center;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
`

export const HeaderSearch = styled.div` 
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
    margin-bottom: 40px;
    z-index: 2;
`

export const StoreCard = styled(CardComponent)` 
    display: flex;
    flex-direction: column;
    z-index: 2;
`

export const HeaderLogoContainer = styled.div` 
    height: 125px;
    width: 250px;
`

export const HeaderLogo = styled.img` 
    width: 100%;
    height: 100%;
    object-fit: cover; 
`

export const CardInfo = styled.div` 
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`
export const CardBody = styled.div` 
    display: flex;
`
export const CardButton = styled.div` 
    position: absolute;
    top: 3px;
    width: 100px;
    height: 4px;
    background-color: ${prop => prop.theme.colors.gray};
    align-self: center;

    &:hover{ 
        cursor: pointer;
    }
`
export const CardTitle = styled.div` 
    font-size: 18px;
    font-weight: bold;
`

export const CardSubtitle = styled.div` 
    font-size: 14px;
`
export const CardDelivery = styled.div` 
    font-size: 12px;
`
export const HomePageContainer = styled.div` 
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
`

export const HomePromos = styled.div` 
    border-radius: 10px;
`

export const HomeCategoriesContainer = styled.div` 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;

`
export const CategoryItemCard = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
`

export const Overlay = styled.div`
    position: absolute;   
    height: 100%;   
    width: 100%;   
    top: 0;   
    background-color: rgba(0, 0, 0, 0.2);   
    opacity: 1;  
`

export const CategoryItemTitle = styled.div`
    text-align: center;
    word-break: break-word;
    color: white;
    font-size: 22px;
    font-weight: bold;
    z-index: 2;
    width: 135px;
`
export const PromoText = styled.div`
    position: absolute;
    z-index: 2;
    color: white;
    font-size: 20px;
    font-weight: bold;
    bottom: 60px;
    right: 20px;
    text-align: right;
    max-width: 85%;
`
export const OverlayHeader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    color: #FFF;
    background: rgba(2, 30, 81, 0.4);
    z-index: 1;
`

