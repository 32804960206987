import React from 'react';
import { useTranslation } from 'react-i18next';
import { FooterComponent } from './style';

export default function NoProductFooter() {
    const { t } = useTranslation();

    return <FooterComponent>
        {t('products.notAvailable')}
    </FooterComponent>;
}