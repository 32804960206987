import { func, string } from "prop-types";
import { useState } from "react";
import { searchProduct } from "../../services/products/searchProduct";
import Loader from "../../components/Loader";
import { useRouteNavigation } from "../../hooks/useRouteNavigation";
import SearchOverlay from "../SearchOverlay";
import { useParams } from "react-router";
import { useDebounce } from "react-use";

const ProductsSearch = ({ searchValue, setClose }) => {
  const { storeId, categoryId } = useParams();
  const goToRoute = useRouteNavigation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useDebounce(
    () => {
      if (searchValue) {
        setLoading(true);

        searchProduct(searchValue, storeId)
          .then(setProducts)
          .finally(() => setLoading(false));
      } else {
        setProducts([]);
      }
    },
    1000,
    [searchValue]
  );

  const onSelect = (item) => {
    goToRoute(`/category/${categoryId}/products/${item._id}`);
  };

  return loading ? (
    <Loader />
  ) : (
    <SearchOverlay
      items={products}
      onSelect={(item) => {
        onSelect(item);
        setClose();
      }}
    />
  );
};

ProductsSearch.propTypes = {
  searchValue: string,
  setClose: func,
};

export default ProductsSearch;
