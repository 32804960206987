import React, { useEffect, useState } from "react";
import { BackButton } from "../BackButton";
import { useGoBack } from "../../hooks/useGoBack";
import { useShop } from "../../context/shop";
import {
  CategoryName,
  CategoryHeader,
  CategoryContainer,
  OverlayHeader,
} from "./style";
import { useParams } from "react-router";
import findCategoryById from "../../helpers/categories/findCategoryById";

export const CategoriesHeader = () => {
  const goBack = useGoBack();
  const { categoryId } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { categories } = useShop();

  useEffect(() => {
    if (categories.length > 0) {
      setSelectedCategory(findCategoryById(categories, categoryId) ?? null);
    }
  }, [categoryId, categories]);

  return (
    <CategoryHeader img={selectedCategory?.img_url}>
      <CategoryContainer>
        <BackButton onClick={goBack} />
        <CategoryName>{selectedCategory?.name}</CategoryName>
      </CategoryContainer>
      <OverlayHeader />
    </CategoryHeader>
  );
};
