import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import { CartModalContainer, ProductGroup, ProductInfo, ProductOptions, ProductComment, ProductCommentTitle, CartModalButton, CartModalBody } from './style';
import { ProductDescription } from '../../ProductDetail/style';
import { IoMdClose } from 'react-icons/io'
import formatNumber from '../../../helpers/formatNumber';
import truncateWithDots from '../../../helpers/truncateWithDots';

export default function CartModal(props) {
    const { t } = useTranslation();
    const { show, onClose, item } = props;
    return <Modal show={show}>
        <CartModalContainer>
            <CartModalButton onClick={onClose}><IoMdClose /></CartModalButton>
            <CartModalBody>
                <ProductInfo>
                    <div>
                        {`${item.quantity}x`}
                    </div>
                    <div>
                        {item.name}
                    </div>
                    <div>
                        {formatNumber(item.price)}
                    </div>
                </ProductInfo>
                <ProductDescription>
                    {truncateWithDots(item.description, 80)}
                </ProductDescription>
                <ProductOptions>
                    {
                        item.optionsGroup.map((group) => {
                            return <div key={group.id}>
                                <ProductGroup>{group.name}</ProductGroup>
                                {group?.options.map((option) => {
                                    return <div key={option.id}>
                                        {option.name} - {formatNumber(option.salePrice)}
                                    </div>
                                })}
                            </div>
                        })
                    }
                </ProductOptions>
                {item.comments &&
                    <>
                        <ProductCommentTitle>{t('cart.comments')}:</ProductCommentTitle>
                        <ProductComment>{item.comments}</ProductComment>
                    </>
                }
            </CartModalBody>
        </CartModalContainer>
    </Modal>
}

CartModal.propTypes = {
    show: propTypes.bool,
    onClose: propTypes.func,
}
