import React from 'react'
import { ProductPriceContainer, ProductDescription, ProductDetailCards, ProductDetailComments, ProductName, ProductPrice } from '../../style'
import propTypes from 'prop-types';
import Textarea from '../../../../components/Textarea';
import { useTranslation } from 'react-i18next';
import ProductDetailCard from './ProductDetailCard';
import formatNumber from '../../../../helpers/formatNumber';

export default function ProductDetailOptions(props) {
    const {
        name,
        description,
        optionsGroup,
        groups,
        comments,
        price,
        toggleOption,
        selectOption,
        onComment } = props;
    const { t } = useTranslation();

    return (<>
        <div>
            <ProductName >
                {name}
            </ProductName>
            <ProductDescription >
                {description}
            </ProductDescription>
        </div>
        <ProductDetailCards>
            {
                optionsGroup?.map((optionGroup) => {
                    return <ProductDetailCard
                        key={optionGroup._id}
                        required={optionGroup.required}
                        type={optionGroup.type}
                        selectedOptions={groups?.find((group) => group.id === optionGroup._id)?.options}
                        onItemChecked={toggleOption}
                        onItemSelected={selectOption}
                        {...optionGroup}
                    />
                })
            }
            <ProductDetailComments>
                <Textarea label={t('detail.comments')} value={comments} onChange={onComment} />
            </ProductDetailComments>
            <ProductPriceContainer>
                <ProductPrice>
                    {formatNumber(price)}
                </ProductPrice>
            </ProductPriceContainer>
        </ProductDetailCards>
    </>

    )
}

ProductDetailOptions.propTypes = {
    name: propTypes.string,
    description: propTypes.string,
    optionsGroup: propTypes.array,
    groups: propTypes.array,
    comments: propTypes.string,
    toggleOption: propTypes.func,
    selectOption: propTypes.func,
    onComment: propTypes.func,
}
