import styled from "styled-components";

export const CartModalContainer = styled.div` 
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
`

export const CartModalButton = styled.div` 
    align-self: flex-end;
    background-color: ${prop => prop.theme.colors.red};
    padding: 2px 5px;
    border-radius: 40px;
    border: 1px solid red;
    font-size: 18px;
    color: white;
    text-align: center;
    position: absolute;
    top: -10px;
    right: -10px;

    &:hover {
        cursor: pointer;
    }
`

export const CartModalBody = styled.div` 
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    max-height: 80%;
`

export const ProductInfo = styled.div` 
    display: flex;
    justify-content: space-between;
    gap: 20px;
    font-size: 16px;
    font-weight: bold;
    
`

export const ProductDescription = styled.div` 
    color: ${prop => prop.theme.colors.gray};
    font-size: 14px;
    margin-top: 10px;
   
`
export const ProductOptions = styled.div` 
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    margin-top: 10px;

    max-height: 250px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        display: none;
    }
`

export const ProductGroup = styled.div` 
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    font-weight: bold;
    color: ${prop => prop.theme.colors.grayDark};
`
export const ProductCommentTitle = styled.div` 
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 15px;
    margin-top: 10px;
    font-weight: bold;
`

export const ProductComment = styled.div` 
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;

`
