import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import Button from '../../components/Button';
import { toast } from 'react-toastify';
import { SuccessModalContainer, ProductInfo, SuccessOptions, SuccessModalBody, SuccessInfo } from './style';
import { useCart } from '../../context/cart';
import { getCart } from "../../services/sales/getCart";
import { useAddress } from '../../context/address';
import { payOrder } from '../../services/payments/payOrder';
import { useParams } from 'react-router';

export default function ChannelModal(props) {
    const { deliveryAddress } = useAddress();
    const { storeId, clientId } = useParams();
    const [locations, setLocations] = useState([]);
    const [address,] = deliveryAddress;
    const { cart } = useCart();
    const [modalChannels, setModalChannels] = useState(false);
    const { t } = useTranslation();

    let params = {
        channelId: cart.channelId,
        username: locations?.client?.username,
        text: 'CONTINUE_ORDER',
        idSale: cart.id,
        paymentMethod: 'card',
        address: null,
        location: {
            lat: 0,
            long: 0,
        },
        deliveryStatus: 0,
    }
    useEffect(() => {
        getCart(storeId, clientId).then((location) => {
            setLocations(location);
        });
    }, [storeId, clientId]);
    const payWithMercado = async () => {
        if (cart.clientId === 'qrcode') {
            setModalChannels(true)
        } else {
            toast.success(t('payment.paySuccess'));
            if (locations?.location?.coordinates[0] !== 0) {
                params = {
                    ...params,
                    location: {
                        lat: locations?.location?.coordinates[0],
                        long: locations?.location?.coordinates[1],
                    },
                    address: address.additionalInfo ? `${locations?.location?.address}. ${address.additionalInfo}` : locations?.location?.address,
                    deliveryStatus: 1
                }
            }
            const url = await payOrder(params);
            window.open('https://'+url, '_blank');
        }
        sessionStorage.removeItem('cart');
    }

    return <Modal show={true}>
        <SuccessModalContainer>
            <SuccessModalBody>
                <SuccessInfo>
                {t('cart.success')}
                </SuccessInfo>
                {cart.clientId === 'qrcode' &&
                    <ProductInfo>
                        {t('cart.channel')}
                    </ProductInfo>
                }
                <SuccessOptions>
                <Button title={`${t('cart.return-channel')}`} onClick={() => { payWithMercado()}}
                />
                    {cart.clientId === 'qrcode' && <ChannelModal
                    show={modalChannels}
                    onClose={() => { setModalChannels(false) }} />}
                </SuccessOptions>
            </SuccessModalBody>
        </SuccessModalContainer>
    </Modal>
}

ChannelModal.propTypes = {
    show: propTypes.bool,
    onClose: propTypes.func,
}