import axios from 'axios';
import { productBackToFront, productFrontToBack } from '../adapters/product';

export const editProduct = async ({ saleId, products }) => {
    const url = `${process.env.REACT_APP_TEST_URL}/marketplace/sale/editProduct`;

    const sale = { sale: { _id: saleId, car: products.map(productFrontToBack) } };

    const { data } = await axios.post(url, sale);

    return { ...data.sale, car: data.sale.car.map(productBackToFront) };
}


