import React, { createContext, useContext } from 'react'
import { useState } from 'react';

export const ImageContext = createContext(null)

export default function ImageProvider({ children }) {
    const [contextImageGallery, setContextImageGallery] = useState([]);

    const images = {
        contextImageGallery: [contextImageGallery, setContextImageGallery],
    }

    return <ImageContext.Provider value={images}>{children}</ImageContext.Provider>
}

export const useImages = () => {
    return useContext(ImageContext);
} 