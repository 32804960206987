import axios from 'axios';

/**
 * info type definition
 * @typedef {Object} info
 * @property {string} campaign
 * @property {string} description
 * @property {string} clientName
 * @property {string} sale
 * @property {number} lat
 * @property {number} long
 * @property {string} address
 */
export const createQuote = async (info) => {
    const url = `${process.env.REACT_APP_DELIVERY_URL}/quote`;

    const { data } = await axios.post(url, info);

    return data;
}
