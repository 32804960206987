import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { AlertBody, AlertComponent, AlertContainer, AlertMain, AlertText, AlertTitle, ChannelList, } from './style';
import { Channel } from '../../../components/Channel';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getChannel } from '../../../services/getChannel';
import { useCart } from '../../../context/cart';

export const CancelledOrderAlert = ({ show }) => {
    const { storeId } = useParams();
    const { cart } = useCart();
    const [channels, setChannels] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (cart.clientId === 'qrcode') {
            getChannel(storeId)
                .then(setChannels);
        }
    }, [storeId, cart]);

    return <AlertComponent show={show}>
        <AlertMain>
            <AlertContainer>
                <AlertBody>
                    <AlertTitle>
                        {t('orders.cancelled')}
                    </AlertTitle>
                    <AlertText>
                        {t('orders.contactUs')}
                    </AlertText>
                    <ChannelList>
                        {
                            channels.map(channel => <Channel
                                key={channel._id}
                                type={channel.channelType.key}
                                name={channel.name}
                                clickable={false}
                            />)
                        }
                    </ChannelList>
                </AlertBody>
            </AlertContainer>
        </AlertMain>
    </AlertComponent>
}

CancelledOrderAlert.propTypes = {
    show: propTypes.bool,
}



