import styled from "styled-components";

export const CategoryName = styled.div`
  color: ${(prop) => prop.theme.colors.primary};
  font-weight: bold;
  font-size: 22px;
  margin: 15px 0px;
`;

export const SubcategoryName = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0px;
  color: ${(prop) => prop.theme.colors.grayMedium};
`;

export const SubcategoryList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PreviousCategory = styled.div`
  margin-top: 10px;
  margin-bottom: 25px;
  color: black;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
`;
