import styled from "styled-components";
export const StyledBurger = styled.button`
  position: fixed;
  top: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 22px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 185;
  align-self: flex-end;

  &:focus {
    outline: none;
  }

  div {
    width: 22px;
    height: 2px;
    background: ${(prop) => prop.theme.colors.primary};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;
