import styled from "styled-components";

export const ProductInput = styled.div` 
    font-size: 18px;
    font-weight: 500;
    color: black;
    background-color: ${prop => prop.theme.colors.grayBackground};
    margin-top: 20px;
    padding: 8px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ProductNameContainer = styled.div` 
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-weight: lighter;   
    font-size: 30px;
`

export const ProductName = styled.div`
    font-weight: 400;
    font-size: 18px;
    margin-left: 15px; 
`
export const PriceName = styled.div`
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    margin-left: 4px; 
`
export const ProductStock = styled.div`
    display: inline-block; 
    font-weight: 100;
    font-size: 14px;
    margin-left: 30px; 
`