import React from 'react';
import { ChannelImage, ChannelContainer, ChannelName, ChannelLinkContainer } from './style';
import instagram from '../../assets/images/channels/instagram.png';
import whatsapp from '../../assets/images/channels/whatsapp.svg';
import telegram from '../../assets/images/channels/telegram.svg';
import messenger from '../../assets/images/channels/messenger.svg';
import propTypes from 'prop-types';
import { useCart } from '../../context/cart';


export const Channel = (props) => {
    const { cart } = useCart();

    const images = {
        'instagram': instagram,
        'whatsapp': whatsapp,
        'messenger': messenger,
        'telegram': telegram
    }

    function getHref() {
        if (props.type === 'telegram') {
            return `https://t.me/${props.extras.name}?start=order-${cart.id}`
        }
        if (props.type === 'whatsapp') {
            return `https://wa.me/${props.extras.source}?text=order-${cart.id}`
        }
    }

    return props.clickable
        ? <ChannelLinkContainer href={getHref()} target="_blank">
            <ChannelImage src={images[props.type]} /> <ChannelName> {props.name} </ChannelName>
        </ChannelLinkContainer>
        : <ChannelContainer>
            <ChannelImage src={images[props.type]} /> <ChannelName> {props.name} </ChannelName>
        </ChannelContainer>
        ;
}

Channel.propTypes = {
    type: propTypes.string,
    name: propTypes.string,
    extras: propTypes.object,
    clickable: propTypes.bool,
}

Channel.defaultProps = {
    clickable: true
}  
