export const codes = [
    { "label": "BD +880", "value": "880" },
    { "label": "BE +32", "value": "32" },
    { "label": "BF +226", "value": "226" },
    { "label": "BG +359", "value": "359" },
    { "label": "BA +387", "value": "387" },
    { "label": "BB +1-246", "value": "1-246" },
    { "label": "WF +681", "value": "681" },
    { "label": "BL +590", "value": "590" },
    { "label": "BM +1-441", "value": "1-441" },
    { "label": "BN +673", "value": "673" },
    { "label": "BO +591", "value": "591" },
    { "label": "BH +973", "value": "973" },
    { "label": "BI +257", "value": "257" },
    { "label": "BJ +229", "value": "229" },
    { "label": "BT +975", "value": "975" },
    { "label": "JM +1-876", "value": "1-876" },
    { "label": "BV", "value": "" },
    { "label": "BW +267", "value": "267" },
    { "label": "WS +685", "value": "685" },
    { "label": "BQ +599", "value": "599" },
    { "label": "BR +55", "value": "55" },
    { "label": "BS +1-242", "value": "1-242" },
    { "label": "JE +44-1534", "value": "44-1534" },
    { "label": "BY +375", "value": "375" },
    { "label": "BZ +501", "value": "501" },
    { "label": "RU +7", "value": "7" },
    { "label": "RW +250", "value": "250" },
    { "label": "RS +381", "value": "381" },
    { "label": "TL +670", "value": "670" },
    { "label": "RE +262", "value": "262" },
    { "label": "TM +993", "value": "993" },
    { "label": "TJ +992", "value": "992" },
    { "label": "RO +40", "value": "40" },
    { "label": "TK +690", "value": "690" },
    { "label": "GW +245", "value": "245" },
    { "label": "GU +1-671", "value": "1-671" },
    { "label": "GT +502", "value": "502" },
    { "label": "GS", "value": "" },
    { "label": "GR +30", "value": "30" },
    { "label": "GQ +240", "value": "240" },
    { "label": "GP +590", "value": "590" },
    { "label": "JP +81", "value": "81" },
    { "label": "GY +592", "value": "592" },
    { "label": "GG +44-1481", "value": "44-1481" },
    { "label": "GF +594", "value": "594" },
    { "label": "GE +995", "value": "995" },
    { "label": "GD +1-473", "value": "1-473" },
    { "label": "GB +44", "value": "44" },
    { "label": "GA +241", "value": "241" },
    { "label": "SV +503", "value": "503" },
    { "label": "GN +224", "value": "224" },
    { "label": "GM +220", "value": "220" },
    { "label": "GL +299", "value": "299" },
    { "label": "GI +350", "value": "350" },
    { "label": "GH +233", "value": "233" },
    { "label": "OM +968", "value": "968" },
    { "label": "TN +216", "value": "216" },
    { "label": "JO +962", "value": "962" },
    { "label": "HR +385", "value": "385" },
    { "label": "HT +509", "value": "509" },
    { "label": "HU +36", "value": "36" },
    { "label": "HK +852", "value": "852" },
    { "label": "HN +504", "value": "504" },
    { "label": "HM", "value": " " },
    { "label": "VE +58", "value": "58" },
    { "label": "PR +1-787", "value": "1-787" },
    { "label": "PS +970", "value": "970" },
    { "label": "PW +680", "value": "680" },
    { "label": "PT +351", "value": "351" },
    { "label": "SJ +47", "value": "47" },
    { "label": "PY +595", "value": "595" },
    { "label": "IQ +964", "value": "964" },
    { "label": "PA +507", "value": "507" },
    { "label": "PF +689", "value": "689" },
    { "label": "PG +675", "value": "675" },
    { "label": "PE +51", "value": "51" },
    { "label": "PK +92", "value": "92" },
    { "label": "PH +63", "value": "63" },
    { "label": "PN +870", "value": "870" },
    { "label": "PL +48", "value": "48" },
    { "label": "PM +508", "value": "508" },
    { "label": "ZM +260", "value": "260" },
    { "label": "EH +212", "value": "212" },
    { "label": "EE +372", "value": "372" },
    { "label": "EG +20", "value": "20" },
    { "label": "ZA +27", "value": "27" },
    { "label": "EC +593", "value": "593" },
    { "label": "IT +39", "value": "39" },
    { "label": "VN +84", "value": "84" },
    { "label": "SB +677", "value": "677" },
    { "label": "ET +251", "value": "251" },
    { "label": "SO +252", "value": "252" },
    { "label": "ZW +263", "value": "263" },
    { "label": "SA +966", "value": "966" },
    { "label": "ES +34", "value": "34" },
    { "label": "ER +291", "value": "291" },
    { "label": "ME +382", "value": "382" },
    { "label": "MD +373", "value": "373" },
    { "label": "MG +261", "value": "261" },
    { "label": "MF +590", "value": "590" },
    { "label": "MA +212", "value": "212" },
    { "label": "MC +377", "value": "377" },
    { "label": "UZ +998", "value": "998" },
    { "label": "MM +95", "value": "95" },
    { "label": "ML +223", "value": "223" },
    { "label": "MO +853", "value": "853" },
    { "label": "MN +976", "value": "976" },
    { "label": "MH +692", "value": "692" },
    { "label": "MK +389", "value": "389" },
    { "label": "MU +230", "value": "230" },
    { "label": "MT +356", "value": "356" },
    { "label": "MW +265", "value": "265" },
    { "label": "MV +960", "value": "960" },
    { "label": "MQ +596", "value": "596" },
    { "label": "MP +1-670", "value": "1-670" },
    { "label": "MS +1-664", "value": "1-664" },
    { "label": "MR +222", "value": "222" },
    { "label": "IM +44-1624", "value": "44-1624" },
    { "label": "UG +256", "value": "256" },
    { "label": "TZ +255", "value": "255" },
    { "label": "MY +60", "value": "60" },
    { "label": "MX +52", "value": "52" },
    { "label": "IL +972", "value": "972" },
    { "label": "FR +33", "value": "33" },
    { "label": "IO +246", "value": "246" },
    { "label": "SH +290", "value": "290" },
    { "label": "FI +358", "value": "358" },
    { "label": "FJ +679", "value": "679" },
    { "label": "FK +500", "value": "500" },
    { "label": "FM +691", "value": "691" },
    { "label": "FO +298", "value": "298" },
    { "label": "NI +505", "value": "505" },
    { "label": "NL +31", "value": "31" },
    { "label": "NO +47", "value": "47" },
    { "label": "NA +264", "value": "264" },
    { "label": "VU +678", "value": "678" },
    { "label": "NC +687", "value": "687" },
    { "label": "NE +227", "value": "227" },
    { "label": "NF +672", "value": "672" },
    { "label": "NG +234", "value": "234" },
    { "label": "NZ +64", "value": "64" },
    { "label": "NP +977", "value": "977" },
    { "label": "NR +674", "value": "674" },
    { "label": "NU +683", "value": "683" },
    { "label": "CK +682", "value": "682" },
    { "label": "XK", "value": "" },
    { "label": "CI +225", "value": "225" },
    { "label": "CH +41", "value": "41" },
    { "label": "CO +57", "value": "57" },
    { "label": "CN +86", "value": "86" },
    { "label": "CM +237", "value": "237" },
    { "label": "CL +56", "value": "56" },
    { "label": "CC +61", "value": "61" },
    { "label": "CA +1", "value": "1" },
    { "label": "CG +242", "value": "242" },
    { "label": "CF +236", "value": "236" },
    { "label": "CD +243", "value": "243" },
    { "label": "CZ +420", "value": "420" },
    { "label": "CY +357", "value": "357" },
    { "label": "CX +61", "value": "61" },
    { "label": "CR +506", "value": "506" },
    { "label": "CW +599", "value": "599" },
    { "label": "CV +238", "value": "238" },
    { "label": "CU +53", "value": "53" },
    { "label": "SZ +268", "value": "268" },
    { "label": "SY +963", "value": "963" },
    { "label": "SX +599", "value": "599" },
    { "label": "KG +996", "value": "996" },
    { "label": "KE +254", "value": "254" },
    { "label": "SS +211", "value": "211" },
    { "label": "SR +597", "value": "597" },
    { "label": "KI +686", "value": "686" },
    { "label": "KH +855", "value": "855" },
    { "label": "KN +1-869", "value": "1-869" },
    { "label": "KM +269", "value": "269" },
    { "label": "ST +239", "value": "239" },
    { "label": "SK +421", "value": "421" },
    { "label": "KR +82", "value": "82" },
    { "label": "SI +386", "value": "386" },
    { "label": "KP +850", "value": "850" },
    { "label": "KW +965", "value": "965" },
    { "label": "SN +221", "value": "221" },
    { "label": "SM +378", "value": "378" },
    { "label": "SL +232", "value": "232" },
    { "label": "SC +248", "value": "248" },
    { "label": "KZ +7", "value": "7" },
    { "label": "KY +1-345", "value": "1-345" },
    { "label": "SG +65", "value": "65" },
    { "label": "SE +46", "value": "46" },
    { "label": "SD +249", "value": "249" },
    { "label": "DO +1-809", "value": "1-809" },
    { "label": "DM +1-767", "value": "1-767" },
    { "label": "DJ +253", "value": "253" },
    { "label": "DK +45", "value": "45" },
    { "label": "VG +1-284", "value": "1-284" },
    { "label": "DE +49", "value": "49" },
    { "label": "YE +967", "value": "967" },
    { "label": "DZ +213", "value": "213" },
    { "label": "US +1", "value": "1" },
    { "label": "UY +598", "value": "598" },
    { "label": "YT +262", "value": "262" },
    { "label": "UM +1", "value": "1" },
    { "label": "LB +961", "value": "961" },
    { "label": "LC +1-758", "value": "1-758" },
    { "label": "LA +856", "value": "856" },
    { "label": "TV +688", "value": "688" },
    { "label": "TW +886", "value": "886" },
    { "label": "TT +1-868", "value": "1-868" },
    { "label": "TR +90", "value": "90" },
    { "label": "LK +94", "value": "94" },
    { "label": "LI +423", "value": "423" },
    { "label": "LV +371", "value": "371" },
    { "label": "TO +676", "value": "676" },
    { "label": "LT +370", "value": "370" },
    { "label": "LU +352", "value": "352" },
    { "label": "LR +231", "value": "231" },
    { "label": "LS +266", "value": "266" },
    { "label": "TH +66", "value": "66" },
    { "label": "TF", "value": "" },
    { "label": "TG +228", "value": "228" },
    { "label": "TD +235", "value": "235" },
    { "label": "TC +", "value": "1-649" },
    { "label": "LY +218", "value": "218" },
    { "label": "VA +379", "value": "379" },
    { "label": "VC +1-784", "value": "1-784" },
    { "label": "AE +971", "value": "971" },
    { "label": "AD +376", "value": "376" },
    { "label": "AG +1-268", "value": "1-268" },
    { "label": "AF +93", "value": "93" },
    { "label": "AI +1-264", "value": "1-264" },
    { "label": "VI +1-340", "value": "1-340" },
    { "label": "IS +354", "value": "354" },
    { "label": "IR +98", "value": "98" },
    { "label": "AM +374", "value": "374" },
    { "label": "AL +355", "value": "355" },
    { "label": "AO +244", "value": "244" },
    { "label": "AQ", "value": "" },
    { "label": "AS +1-684", "value": "1-684" },
    { "label": "AR +54", "value": "54" },
    { "label": "AU +61", "value": "61" },
    { "label": "AT +43", "value": "43" },
    { "label": "AW +297", "value": "297" },
    { "label": "IN +91", "value": "91" },
    { "label": "AX +358-18", "value": "358-18" },
    { "label": "AZ +994", "value": "994" },
    { "label": "IE +353", "value": "353" },
    { "label": "ID +62", "value": "62" },
    { "label": "UA +380", "value": "380" },
    { "label": "QA +974", "value": "974" },
    { "label": "MZ +258", "value": "258" }
]