import React from "react";
import { Body, AppFooter, LayoutContainer, FooterItem } from "./style";
import { BsHouse, BsCart2 } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { useRouteNavigation } from "../../hooks/useRouteNavigation";
import Badge from "../../components/Badge";
import AppHeader from "../../components/AppHeader";
import { useCart } from "../../context/cart";

export default function Main(props) {
  const location = useLocation();
  const goToRoute = useRouteNavigation();
  const { cart } = useCart();

  const isSelected = (location, pathname) => {
    const name = location.slice(location.length - 5);
    return name === pathname;
  };

  return (
    <LayoutContainer>
      <AppHeader />
      <Body>{props.children}</Body>
      <AppFooter>
        <FooterItem
          onClick={() => goToRoute("/home")}
          selected={isSelected(location.pathname, "/home")}
        >
          <BsHouse />
        </FooterItem>
        <FooterItem
          onClick={() => goToRoute("/cart")}
          selected={isSelected(location.pathname, "/cart")}
        >
          <Badge total={cart.products?.length}>
            <BsCart2 />
          </Badge>
        </FooterItem>
        {/* <FooterItem onClick={() => goToRoute('/profile')} selected={location.pathname === '/profile'}>
                <BsPerson />
            </FooterItem> */}
      </AppFooter>
    </LayoutContainer>
  );
}
