import propTypes from 'prop-types';
import React from 'react';
import { AddressItemActions, AddressItemButton, AddressItemContainer, AddressItemDescription, AddressItemInfo, AddressItemName } from './style';
import { RiPencilFill } from 'react-icons/ri';
import { FiTrash2 } from 'react-icons/fi';

export default function AddressItem({ name, description }) {
    return <AddressItemContainer>
        <AddressItemInfo>
            <AddressItemName>
                {name}
            </AddressItemName>
            <AddressItemDescription>
                {description}
            </AddressItemDescription>
        </AddressItemInfo>
        <AddressItemActions>
            <AddressItemButton>
                <RiPencilFill />
            </AddressItemButton>
            <AddressItemButton>
                <FiTrash2 />
            </AddressItemButton>
        </AddressItemActions>
    </AddressItemContainer>;
}
AddressItem.propTypes = {
    name: propTypes.string,
    description: propTypes.string,
}