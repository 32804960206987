import { func, string } from "prop-types";
import { useEffect, useState } from "react";
import { useShop } from "../../context/shop";
import normalizeString from "../../helpers/normalizeString";
import { useRouteNavigation } from "../../hooks/useRouteNavigation";
import SearchOverlay from "../SearchOverlay";

const CategoriesSearch = ({ searchValue, setClose }) => {
  const { categories } = useShop();
  const goToRoute = useRouteNavigation();
  const [filteredCategories, setFilteredCategories] = useState([]);

  useEffect(() => {
    const filter = categories.filter((category) =>
      normalizeString(category.name)
        .toLowerCase()
        .includes(normalizeString(searchValue).toLowerCase())
    );

    setFilteredCategories(filter);
  }, [categories, searchValue]);

  const onSelect = (item) => {
    const subcategories = item.childrens;

    if (subcategories.length > 0) {
      // Shows the subcategories page
      goToRoute(`/category/${item.id}`);
    } else {
      // Shows the products of a selected category
      goToRoute(`/category/${item.id}/products`);
    }
  };

  return (
    <SearchOverlay
      items={filteredCategories}
      onSelect={(item) => {
        onSelect(item);
        setClose();
      }}
    />
  );
};

CategoriesSearch.propTypes = {
  searchValue: string,
  setClose: func,
};

export default CategoriesSearch;
