import React from 'react'
import propTypes from 'prop-types';
import { ErrorMessage } from './style';
import { useTranslation } from 'react-i18next';

export default function DateValidation({ errors }) {
    const { t } = useTranslation();

    const isRequired = (errors.exp_year || errors.exp_month) && (errors.exp_year?.type === "required" || errors.exp_month?.type === "required");
    const errorYear = errors.exp_year && (errors.exp_year?.type === "min");
    const errorMonth = errors.exp_month && (errors.exp_month?.type === "min" || errors.exp_month?.type === "max");

    return (
        <>
            {
                isRequired ?
                    <ErrorMessage>{t('validations.requiredField')}</ErrorMessage>

                    : errorYear ? <ErrorMessage>{t('validations.invalidYear')}</ErrorMessage>
                        : errorMonth ? <ErrorMessage>{t('validations.invalidMonth')}</ErrorMessage>
                            : <ErrorMessage>{errors.exp_year?.message}</ErrorMessage>
            }

        </>
    )
}

DateValidation.propTypes = {
    errors: propTypes.object,
}
