import React from "react";
import { useTranslation } from "react-i18next";
import truncateWithDots from "../../../../helpers/truncateWithDots";
import { CategoryItem } from "../CategoryItem";
import propTypes from "prop-types";
import { useRouteNavigation } from "../../../../hooks/useRouteNavigation";
import { HomeCategoriesContainer } from "../../style";
import { useShop } from "../../../../context/shop";

export const HomeCategories = () => {
  const { t } = useTranslation();
  const { categories } = useShop();
  const goToRoute = useRouteNavigation();

  const selectCategory = async (categoryId) => {
    const selectedCategory = categories.find(
      (category) => category.id === categoryId
    );
    const subcategories = selectedCategory.childrens;

    if (subcategories.length > 0) {
      // Shows the subcategories page
      goToRoute(`/category/${categoryId}`);
    } else {
      // Shows the products of a selected category
      goToRoute(`/category/${categoryId}/products`);
    }
  };
  return (
    <HomeCategoriesContainer>
      {categories.length === 0 ? (
        <div>
          <h3>{t("categories.empty")}</h3>
        </div>
      ) : (
        categories.filter(category => category.active).map((category) => (
          <CategoryItem
            key={category.id}
            name={truncateWithDots(category.name, 35)}
            img_url={category.img_url}
            onClick={() => selectCategory(category.id)}
          />
        ))
      )}
    </HomeCategoriesContainer>
  );
};

HomeCategories.propTypes = {
  categories: propTypes.array,
};
