import styled from "styled-components";
import { css } from "styled-components";

export const AlertComponent = styled.div` 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(2, 30, 81, 0.4);
    display: none;
    z-index: 3; 

    ${({ show }) => show && css`
         display: block;
      `
    }
`

export const AlertMain = styled.div` 
    position: fixed;
    opacity: 1;
    top: 30%;
    width: 80%;
    height: 100px;
    left: 50%;
    transform: translate(-50%,-50%);  
`

export const AlertContainer = styled.div` 
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
`
export const AlertBody = styled.div` 
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
`

export const AlertTitle = styled.div` 
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: ${prop => prop.theme.colors.primary};
`

export const AlertText = styled.div` 
    font-size: 18px;
    margin-bottom: 10px;
    color: ${prop => prop.theme.colors.grayDark};
`

export const ChannelList = styled.div` 
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    margin-top: 10px;
`
