import axios from 'axios';

export const deleteCart = async (cartId) => {
    const url = `${process.env.REACT_APP_TEST_URL}/marketplace/sale/deleteCart/${cartId}`;

    const { data } = await axios.delete(url);

    return data;
}


