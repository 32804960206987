import styled from "styled-components";

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(prop) => prop.theme.colors.background};
  height: 100vh;
  width: 60%;
  text-align: left;
  padding: 60px 40px;
  transition: all 0.3s ease-in-out;
  position: fixed;
  top: 0px;
  right: 0px;
  transform: ${({ open }) => (open ? "translateX(0%)" : "translateX(150%)")};
  z-index: 180;
  overflow-x: hidden;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.primaryDark};
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  justify-content: space-between;
`;

export const StoreAddress = styled.div`
  text-align: center;
  margin-top: 15px;
  color: ${(prop) => prop.theme.colors.grayMedium};
  font-size: 14px;
`;
