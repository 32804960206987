import { useTranslation } from "react-i18next";
import { BsCartX } from "react-icons/bs";
import Button from "../../../components/Button";
import PageHeader from '../../../components/PageHeader';
import { useGoBack } from "../../../hooks/useGoBack";
import { useRouteNavigation } from "../../../hooks/useRouteNavigation";
import { EmptyCartBody, EmptyCartAdd, EmptyCartIcon, EmptyCartText, EmptyCartContainer } from '../style';

export default function EmptyCart() {
    const { t } = useTranslation();
    const goBack = useGoBack();
    const goToRoute = useRouteNavigation();

    return <EmptyCartContainer>
        <PageHeader title={t('cart.title')} onClick={goBack} />
        <EmptyCartBody>
            <EmptyCartIcon>
                <BsCartX />
            </EmptyCartIcon>
            <EmptyCartText>
                {t('cart.empty')}
            </EmptyCartText>
            <EmptyCartAdd>
                {t('cart.addToCart')}
            </EmptyCartAdd>
            <Button title={t('cart.continueShop')} width="65%" outline
                onClick={() => { goToRoute('/home') }}
            />
        </EmptyCartBody>
    </EmptyCartContainer >

}