import styled from "styled-components";

export const SelectContainer = styled.div` 
    display: flex;
    flex-direction: column;
    gap: 8px;
`
export const SelectLabel = styled.label`
    font-size: 16px;
    color: black;
    font-weight: bold;
`
export const SelectOption = styled.div`
    padding: 10px;
`
export const SelectIcon = styled.span`
    margin-right: 8px;
`

export const customStyles = {
    control: () => ({
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '4px',
        border: `1px solid #949bb1`,
        //height: '35px',
        width: '150px',
        overflow: 'hidden',
        height: '35px',
        backgroundColor: '#EFF0F2',
    }),
    placeholder: () => ({
        color: 'black',
        opacity: '0.5',
    }),
    valueContainer: () => ({
        display: 'flex',
        alignItems: 'center',
        padding: '5px 10px',
        width: '150px',
        fontSize: '15px',
    }),
    indicatorsContainer: () => ({
        border: 'none',
        maxWidth: '30px',

    }),
    input: (provided) => ({
        ...provided,
        color: 'transparent',

    }),
}