import axios from "axios";

export const searchProduct = async (search, campaignId, categoryId) => {
    const url = `${process.env.REACT_APP_TEST_URL}/marketplace/search-products`;
    const resp = await axios.post(url, {
        name: search,
        campaign: campaignId,
        categorie: categoryId,
    });

    const data = resp.data;

    return data.map((item) => {
        const imageUrl = Array.isArray(item.imageUrl) ? item.imageUrl : [item.imageUrl];
        return {
            ...item,
            imageUrl,
        };
    });
}