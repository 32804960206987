import React from 'react';
import { List, CategoryName } from './style';
import { array, func } from 'prop-types';
import { IoIosArrowForward } from 'react-icons/io';

const CategoriesList = ({ categories, onClick }) => {
    return (
        <List>
            {categories.map(category => <CategoryName key={category.id} onClick={() => onClick(category)}>
                {category.name}
                {category.childrens.length > 0 && <IoIosArrowForward />}
            </CategoryName>
            )}
        </List>
    )
}

CategoriesList.propTypes = {
    categories: array.isRequired,
    onClick: func,
}

export default CategoriesList;