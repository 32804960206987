import propTypes from 'prop-types';
import React from 'react';
import { BackButton } from '../BackButton';
import CancelButton from '../CancelButton';
import { PageHeaderComponent } from './style';

export default function PageHeader({ cancelButton, onClick, title }) {
    return <PageHeaderComponent>
        {cancelButton ? <CancelButton round={false} onClick={onClick} /> : <BackButton round={false} onClick={onClick} />}
        {title}
    </PageHeaderComponent>;
}

PageHeader.propTypes = {
    cancelButton: propTypes.bool,
    onClick: propTypes.func,
    title: propTypes.string,
}

PageHeader.defaultProps = {
    cancelButton: false
}  
