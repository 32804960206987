import i18n from "i18next";
// import Backend from "i18next-xhr-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./i18n/en.json";
import translationES from "./i18n/es.json";

const fallbackLng = ["es"];
const availableLanguages = ["en", "es"];

const resources = {
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    },

};

i18n
    // .use(Backend)
    // .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;