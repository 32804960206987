import styled from "styled-components";

export const PageHeaderComponent = styled.div` 
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: white;
    color: black;
    font-size: 22px;
    font-weight: bold;
    padding: 15px 5px;
`
