import styled from "styled-components"

export const CartItemContainer = styled.div` 
    display: flex;
    justify-content: space-between;
    background-color: white;
    height: 80px;
    border-bottom: 1px solid ${prop => prop.theme.colors.gray};

    &:last-child {
        border-bottom: none;
    }
`
export const CartItemBody = styled.div` 
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 0px 10px;
    overflow: hidden;
`

export const CartItemCounter = styled.div` 
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${prop => prop.theme.colors.grayBackground};
    font-size: 16px;
    padding: 5px 10px;
    min-width: 15px;
    max-width: 40px;
`

export const CartItemInfo = styled.div` 
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    width: 200px;

`
export const CartProduct = styled.div` 
    color: black;
    font-size: 18px;
    font-weight: 600;
`

export const CartProductDescription = styled.div` 
    color: ${prop => prop.theme.colors.gray};
    font-size: 16px;
    margin-top: 4px;
`
export const CartProductInfo = styled.div` 
    color: ${prop => prop.theme.colors.gray};
    font-size: 14px;
    margin-top: 1px;
`

export const CartProductPrice = styled.div` 
    color: black;
    font-size: 15px;
    font-weight: bold;
    width: 125px;
    text-align: right;
`


export const CartButton = styled.div` 
    font-size: 22px;
    padding: 10px;
    color: white;
    height: 40px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
        cursor: pointer;
    }
`

export const CartDeleteButton = styled(CartButton)` 
    background-color: ${prop => prop.theme.colors.red};
`
export const CartEditButton = styled(CartButton)` 
    background-color: ${prop => prop.theme.colors.secondary};
`
export const CartPreviewButton = styled(CartButton)` 
    background-color: ${prop => prop.theme.colors.gray};
`



