import styled from "styled-components";

export const AddCardContainer = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }
`

export const AddCardBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 20px;
    padding-bottom: 0px;
`

export const AddCardInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 5px;
    background-color: white;
    padding: 20px;
`
export const CardInfo = styled.div`
    display: flex;
    gap: 10px;
`
export const InputGroup = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;   
`

export const InputSubgroup = styled.div`
    display: flex;
    gap: 10px; 
`

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 15px;
    height: 90px;
`

export const InputWrapperWide = styled(InputWrapper)`
    flex: 75%;
`

export const InputWrapperShort = styled(InputWrapper)`
    flex: 25%;
`

export const ErrorMessage = styled.div`
   color: ${prop => prop.theme.colors.red};
   font-size: 14px;
`


