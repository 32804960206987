import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const AppHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 15px;
  background-color: ${(prop) => prop.theme.colors.background};
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.02) 0px 0px 0px 1px;
`;

export const AppHeaderName = styled.div`
  color: ${(prop) => prop.theme.colors.primary};
  font-weight: bold;
  font-size: 14px;
`;

export const AppHeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 30px;
`;

export const AppOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: none;
  z-index: 170;

  ${({ show }) =>
    show &&
    css`
      display: block;
    `}
`;

export const SearchTabInput = styled(motion.input)`
  display: inline-block;
  border: none;
  appearance: none;
  background-color: transparent;
  height: 30px;
  padding: 0px 10px;
  z-index: 201;
  font-size: 16px;
  border-bottom: 1px solid ${(prop) => prop.theme.colors.graySuperLight};

  ::placeholder {
    font-size: 14px;
    color: ${(prop) => prop.theme.colors.grayMedium};
  }

  &:focus {
    outline: none;
  }
`;

// Framer motion variants
export const variants = {
  closed: {
    width: 0,
    opacity: 0,
  },
  opened: {
    width: "calc(90% - 24px)",
    opacity: 1,
  },
};

export const cancelVariants = {
  closed: {
    display: "none",
    opacity: 0,
  },
  opened: {
    zIndex: 201,
    display: "inline",
    opacity: 1,
  },
};

export const searchVariants = {
  closed: {
    display: "none",
    opacity: 0,
  },
  opened: {
    display: "inline",
    opacity: 1,
  },
};
