import { Rings } from "react-loader-spinner";
import { LoaderContainer } from "./style";

const Loader = () => {
  return (
    <LoaderContainer>
      <Rings height="100" width="100" color="#2255ff" ariaLabel="loading" />
    </LoaderContainer>
  );
};

export default Loader;
