import React from 'react';
import propTypes from 'prop-types';
import Modal from "../../../components/Modal";
import { CancelButtons, CancelDescription, CancelTitle, CartModalContainer } from './style';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useShop } from '../../../context/shop';

export default function ConfirmDelete(props) {
    const { show, onClose, onConfirm } = props;
    const { shop } = useShop();
    const { t } = useTranslation();

    return <Modal show={show} onClose={onClose} width="90%">
        <CartModalContainer>
            <CancelTitle>
                {t('cart.cancelConfirm')}
            </CancelTitle>
            <CancelDescription>
                {t('cart.cancelDescription', { name: shop.name })}
            </CancelDescription>
            <CancelButtons>
                <Button outline title={t('cart.yes')} onClick={onConfirm} />
                <Button outline gray title={t('cart.no')} onClick={onClose} />
            </CancelButtons>
        </CartModalContainer>
    </Modal>
}

ConfirmDelete.propTypes = {
    show: propTypes.bool,
    onClose: propTypes.func,
    onConfirm: propTypes.func,
}
