import styled, { css } from "styled-components";

export const CancelButtonComponent = styled.button` 
    outline: none;
    background: white;
    border: 1px solid ${prop => prop.theme.colors.graySuperLight};
    vertical-align: middle;
    padding: 5px;
    border-radius: 50%;
    font-size: 20px;
    display: flex;

    &:hover{
      cursor: pointer;
    }

    ${({ round }) =>
    !round && css`
        border: none;
      `
  }
`
