import styled from "styled-components";

export const AddressDetailContainer = styled.div` 
    background-color: ${prop => prop.theme.colors.background};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`


export const AddressDetailBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px 20px 0px;
`

export const AddressIcon = styled.div` 
    font-size: 20px;
`
export const AddressData = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    gap: 10px;
    padding: 0px 10px;
    background-color: white;
`
export const AddressName = styled.div` 
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-size: 16px;
    color: black;
    padding: 20px;
    height: 50px;
    background-color: white;
`
export const AddressReference = styled.div` 
    height: 60px;
`

export const AddressMap = styled.div` 
    position: relative;
`
export const AddressAutocomplete = styled.div` 
    position: absolute;
    min-width: 310px;
    max-width: 330px;
    top: 15px;
    left: 10px;
`



