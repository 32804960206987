import styled, { css } from "styled-components";

export const RadioButtonContainer = styled.label` 
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
`

export const RadioMark = styled.span`
    display: inline-block;
    position: relative;
    border: 2px solid black;
    width: 16px;
    height: 16px;
    left: 0;
    border-radius: 50%;
    vertical-align: middle;
    transition: all 0.1s ease;


    ${({ checked }) => checked && css`
         border-color: white;
      `
    }
`;

export const RadioContent = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    word-break: break-word;
`;

export const ProductPrice = styled.div`
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    width: 125px;
`

export const RadioButtonComponent = styled.input.attrs({
    type: "checkbox",
})`
    position: absolute;
    visibility: hidden;
    display: none;

    &:checked + ${RadioMark} {
        &::after {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color:  ${prop => prop.theme.colors.secondary};
            position: absolute;
            
        }
    }
   
`
