import React from 'react';
import { BsSearch } from 'react-icons/bs';
import { InputComponent, InputContainer, InputIcon } from './style';
import propTypes from 'prop-types';

export const InputSearch = ({ placeholder, value, onChange }) => {
    return (
        <InputContainer >
            <InputIcon>
                <BsSearch />
            </InputIcon>
            <InputComponent
                type="text"
                name="value"
                autoComplete="false"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
        </InputContainer>
    )
};

InputSearch.propTypes = {
    placeholder: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
}

