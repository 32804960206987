import React from "react";
import {
  SubcategoryList,
  CategoryName,
  SubcategoryName,
  PreviousCategory,
} from "./style";
import { List } from "../CategoriesList/style";
import { array, func, object } from "prop-types";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";

const Subcategories = ({
  selectedCategory,
  subcategories,
  onClick,
  onSelectPrevious,
  onShowAll,
}) => {
  const { t } = useTranslation();

  return (
    <SubcategoryList>
      <PreviousCategory onClick={() => onSelectPrevious(selectedCategory)}>
        <IoIosArrowBack />
        {!selectedCategory?.idParent
          ? t("categories.title")
          : selectedCategory?.name}
      </PreviousCategory>
      <CategoryName>{selectedCategory?.name}</CategoryName>
      <List>
        <SubcategoryName onClick={() => onShowAll(selectedCategory)}>
          {t("categories.showAll")} <IoIosArrowForward />
        </SubcategoryName>
        {subcategories.map((category) => (
          <SubcategoryName key={category.id} onClick={() => onClick(category)}>
            {category.name}
            {category.childrens.length > 0 && <IoIosArrowForward />}
          </SubcategoryName>
        ))}
      </List>
    </SubcategoryList>
  );
};

Subcategories.propTypes = {
  selectedCategory: object,
  subcategories: array.isRequired,
  onClick: func,
  onSelectPrevious: func,
  onShowAll: func,
};

export default Subcategories;
