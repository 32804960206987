import { lighten } from "polished";
import styled, { css } from "styled-components";

export const LayoutContainer = styled.div`
  background-color: white;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SearchIcon = styled.div`
  font-weight: bold;
  font-size: 25px;
  margin-right: 40px;
`;

export const Body = styled.div`
  flex-grow: 1;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  background-color: ${(prop) => prop.theme.colors.background};

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const AppFooter = styled.nav`
  display: flex;
  background-color: white;
  height: 60px;
  padding: 0px 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.02) 0px 0px 0px 1px;
`;

export const FooterItem = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 26px;
  transition: color 0.5s;
  position: relative;

  ${({ selected }) =>
    selected &&
    css`
      svg {
        color: ${(prop) => prop.theme.colors.secondary};
      }
    `}

  &:hover {
    svg {
      color: ${(prop) => lighten(0.25, prop.theme.colors.secondary)};
    }
    cursor: pointer;
  }
`;
