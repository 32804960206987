import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useSwipeable } from "react-swipeable";
import { CartDeleteButton, CartEditButton, CartItemBody, CartItemContainer, CartItemCounter, CartItemInfo, CartProduct, CartProductDescription, CartProductPrice } from './style';
import { FiTrash2 } from 'react-icons/fi';
import { RiPencilFill } from 'react-icons/ri';
import { useCart } from '../../../context/cart';
import { useRouteNavigation } from '../../../hooks/useRouteNavigation';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import truncateWithDots from '../../../helpers/truncateWithDots';
import formatNumber from '../../../helpers/formatNumber';
import getProductTotal from '../../../helpers/getProductTotal';

export default function CartItem(props) {
    const { t } = useTranslation();
    const { deleteProduct } = useCart();
    const [showButton, setShowButton] = useState(false);
    const { product, onClick } = props;
    const { quantity, name, description, lineId } = product;

    const goToRoute = useRouteNavigation();

    const handlers = useSwipeable({
        onSwipedLeft: () => setShowButton(true),
        onSwipedRight: () => { setShowButton(false) },
        preventDefaultTouchmoveEvent: false,
        trackMouse: true,

    });

    const notifyProductDeleted = () => toast.success(t('detail.productDeleted', { product: name }));

    return <CartItemContainer {...handlers}>
        <CartItemBody onClick={!showButton ? onClick : () => { }}>
            <CartItemCounter>{quantity}</CartItemCounter>
            <CartItemInfo >
                <CartProduct>
                    {truncateWithDots(name, 18)}
                </CartProduct>
                <CartProductDescription>
                    {truncateWithDots(description, 18)}
                </CartProductDescription>
            </CartItemInfo>
            {!showButton && <CartProductPrice >
                {formatNumber(getProductTotal(product))}
            </CartProductPrice>}
        </CartItemBody>
        {showButton &&
            <>
                <CartEditButton onClick={() => { goToRoute(`/edit-product/${lineId}`) }}>
                    <RiPencilFill />
                </CartEditButton>
                <CartDeleteButton onClick={() => { deleteProduct(lineId); notifyProductDeleted() }}>
                    <FiTrash2 />
                </CartDeleteButton>
            </>
        }
    </CartItemContainer>;
}

CartItem.propTypes = {
    quantity: propTypes.number,
    name: propTypes.string,
    description: propTypes.string,
    price: propTypes.number,
    lineId: propTypes.string,
    onClick: propTypes.func,
}
