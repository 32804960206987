import { useState, useRef } from "react";
import { useShop } from "../../context/shop";
import { useClickOutside } from "../../hooks/useClickOutside";
import Link from "../Link";
import { AnimatePresence, motion } from "framer-motion";
import {
  AppHeaderContainer,
  AppHeaderInfo,
  AppHeaderName,
  AppOverlay,
  cancelVariants,
  SearchTabInput,
  searchVariants,
  variants,
} from "./style";
import { BsSearch, BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import Burger from "../../components/Burger";
import Menu from "../../components/Menu";
import CategoriesSearch from "../CategoriesSearch";
import ProductsSearch from "../ProductsSearch";
import { SearchOverlayContainer } from "../SearchOverlay/style";

const AppHeader = () => {
  const location = useLocation();
  const { shop } = useShop();
  const { t } = useTranslation();

  // Menu
  const [open, setOpen] = useState(false);
  const node = useRef();
  useClickOutside(node, () => setOpen(false));

  // Search
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");
  const [searchActive, setSearchActive] = useState(!!q);
  const [search, setSearch] = useState(q ?? "");

  const isHome = () => {
    const { pathname } = location;

    const name = pathname.slice(pathname.length - 5);

    return name === "/home";
  };

  const closeSearch = () => {
    setSearchActive(false);
    setSearch("");
  };

  return (
    <AppHeaderContainer>
      <AppHeaderInfo>
        {!searchActive && (
          <Link to={"/home"}>
            <AppHeaderName>{shop.name}</AppHeaderName>
          </Link>
        )}
        <SearchTabInput
          name="search"
          placeholder={
            isHome() ? t("home.searchCategories") : t("products.search")
          }
          onChange={(evt) => setSearch(evt.target.value)}
          value={search}
          initial={false}
          variants={variants}
          animate={searchActive ? "opened" : "closed"}
          transition={{ duration: 0.5 }}
          layout
        />
        <motion.div
          variants={searchVariants}
          initial={false}
          animate={searchActive ? "closed" : "opened"}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <BsSearch
            style={{
              width: "20px",
              height: "20px",
              color: "#021e51",
              marginBottom: "2px",
              zIndex: "10",
            }}
            onClick={() => setSearchActive(true)}
          />
        </motion.div>
        <motion.div
          variants={cancelVariants}
          initial={false}
          animate={searchActive ? "opened" : "closed"}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <BsX
            style={{
              width: "32px",
              height: "32px",
              marginTop: "2px",
              color: "#021e51",
            }}
            onClick={closeSearch}
          />
        </motion.div>
      </AppHeaderInfo>
      <div ref={node}>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={() => setOpen(false)} />
      </div>
      <AppOverlay show={open} />
      <AnimatePresence initial={false}>
        {searchActive && (
          <SearchOverlayContainer
            initial={{
              zIndex: -100,
              opacity: 0,
            }}
            animate={{
              zIndex: 200,
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ delay: 0.5, duration: 0.4 }}
          >
            {isHome() ? (
              <CategoriesSearch setClose={closeSearch} searchValue={search} />
            ) : (
              <ProductsSearch setClose={closeSearch} searchValue={search} />
            )}
          </SearchOverlayContainer>
        )}
      </AnimatePresence>
    </AppHeaderContainer>
  );
};

export default AppHeader;
