import propTypes from 'prop-types';
import React from 'react';
import { InputComponent, InputContainer, InputLabel, InputWrapper } from './style';

const Input = React.forwardRef((props, ref) => {

    const {
        label,
        placeholder,
        type,
        value,
        onChange,
        register,
        autoComplete,
        error,
        ...rest
    } = props;

    return <InputContainer>
        < InputLabel >
            {label}
        </InputLabel >
        <InputWrapper error={error}>
            <InputComponent
                ref={ref}
                placeholder={placeholder}
                type={type}
                value={value}
                autoComplete={autoComplete}
                onChange={onChange}
                {...rest}
            />
        </InputWrapper>
    </InputContainer >
        ;
})

Input.propTypes = {
    label: propTypes.string,
    placeholder: propTypes.string,
    type: propTypes.string,
    value: propTypes.oneOfType([
        propTypes.string,
        propTypes.number,
    ]),
    onChange: propTypes.func,
    register: propTypes.any,
    name: propTypes.string,
    autoComplete: propTypes.string,
    width: propTypes.string,
    error: propTypes.object,
}

Input.defaultProps = {
    type: "text",
    autoComplete: "new-password",
    width: "100%",
}

export default Input;