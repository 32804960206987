import React from "react";
import { CategoriesHeader } from "../../components/CategoriesHeader";
import { ProductsContainer } from "../ProductsByCategory/style";
import { AllProductsPage } from "./AllProductsPage";

export default function AllProductsByCategory() {
  return (
    <ProductsContainer>
      <CategoriesHeader />
      <AllProductsPage />
    </ProductsContainer>
  );
}
