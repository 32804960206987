// Finds the category on the categories tree
const findCategoryById = (categories, categoryId) => {
  let category;
  category = categories.find((category) => category.id === categoryId);

  if (category) {
    return category;
  } else {
    let i = 0;

    while (i < categories.length && !category) {
      category = findCategoryById(categories[i].childrens, categoryId);
      i++;
    }

    return category;
  }
};

export default findCategoryById;
