import { func, object } from "prop-types";
import truncateWithDots from "../../helpers/truncateWithDots";
import { SearchItem, SearchItemImage } from "./style";

const SearchResult = ({ item, onClick }) => {
  const getItemImage = () => {
    let image = item.img_url;

    if (!image) {
      image = item.imageUrl[0];
    }

    return image;
  };

  return (
    <SearchItem onClick={onClick}>
      <SearchItemImage src={getItemImage()} />
      {truncateWithDots(item.name, 45)}
    </SearchItem>
  );
};

SearchResult.propTypes = {
  item: object.isRequired,
  onClick: func,
};

export default SearchResult;
