import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/PageHeader';
import OrderItem from './OrderItem';
import { OrdersBody, OrdersComponent } from './style';

export default function Orders() {
    const { t } = useTranslation();

    return <OrdersComponent>
        <PageHeader title={t('orders.myOrders')} />
        <OrdersBody>
            <OrderItem
                productName={"Product name"}
                productDescription={"Product description"}
                price={"$250.00"}
                date={"25/01/2022"}
                image="https://placekitten.com/200/200"
            />
            <OrderItem
                productName={"Product name"}
                productDescription={"Product description"}
                price={"$250.00"}
                date={"25/01/2022"}
                image="https://placekitten.com/200/200"
            />
            <OrderItem
                productName={"Product name"}
                productDescription={"Product description"}
                price={"$250.00"}
                date={"25/01/2022"}
                image="https://placekitten.com/200/200"
            />
            <OrderItem
                productName={"Product name"}
                productDescription={"Product description"}
                price={"$250.00"}
                date={"25/01/2022"}
                image="https://placekitten.com/200/200"
            />
        </OrdersBody>
    </OrdersComponent>;
}
