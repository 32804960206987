import React from 'react'
import propTypes from 'prop-types';
import { useAddress } from '../../../../context/address';
import { useShop } from '../../../../context/shop';
import { useRouteNavigation } from '../../../../hooks/useRouteNavigation';
import { DeliveryAddressContainer, DeliveryAddressNew, DeliveryAddress } from '../style';
import truncateWithDots from '../../../../helpers/truncateWithDots';
import { RiPencilFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

export default function OrderAddress(props) {
    const { t } = useTranslation();
    const { shop } = useShop();
    const { deliveryAddress } = useAddress();
    const [address,] = deliveryAddress;
    const goToRoute = useRouteNavigation();
    const { delivery, deliveryEnabled } = props;

    return (<DeliveryAddressContainer >
        {!deliveryEnabled ?
            <DeliveryAddress>
                {shop.address}
            </DeliveryAddress>
            : delivery ?
                !address.description ?
                    <DeliveryAddressNew
                        onClick={() => goToRoute('/address-detail')}
                    >
                        {t('addresses.add')}
                    </DeliveryAddressNew> : <DeliveryAddress>
                        {truncateWithDots(address.description, 100)}
                    </DeliveryAddress> : <DeliveryAddress>
                    {shop.address}
                </DeliveryAddress>
        }
        {(delivery && address.description) && <RiPencilFill onClick={() => goToRoute('/address-detail')} />}
    </DeliveryAddressContainer>
    )
}

DeliveryAddress.propTypes = {
    delivery: propTypes.bool,
    deliveryEnabled: propTypes.bool,
}
