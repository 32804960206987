import React from "react";
import Link from "../../../components/Link";
import propTypes from "prop-types";
import Product from "../../../components/Product";
import { ProductsListContainer } from "./style";

export const ProductsByCategoryList = ({ products, categoryId }) => {
  return (
    <ProductsListContainer>
      {products.map((product) => {
        return (
          <div key={product?._id}>
            <Link to={`/category/${categoryId}/products/${product._id}`}>
              <Product {...product} />
            </Link>
          </div>
        );
      })}
    </ProductsListContainer>
  );
};

ProductsByCategoryList.propTypes = {
  products: propTypes.array,
  categoryId: propTypes.string,
};
