import React from 'react';
import propTypes from 'prop-types';
import Modal from '../../../components/Modal';
import { StoreInfo } from '../StoreInfo';

export const StoreInfoModal = ({ store, show, onClose }) => {
    return <Modal show={show} onClose={onClose} top="82%" width="95%">
        <StoreInfo store={store} showButton />
    </Modal>;
}

StoreInfoModal.propTypes = {
    store: propTypes.object,
    show: propTypes.bool,
    onClose: propTypes.func,
}

