import styled from "styled-components";

export const InvalidDeviceContainer = styled.div` 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`

export const ComputerImage = styled.img`
    height: 200px;
    width: 300px;
    margin-bottom: 30px;
`

export const InvalidDeviceText = styled.div` 
    display: flex;
    flex-direction: column;
    align-items: center;    
    gap: 5px;
`

export const InvalidDeviceTitle = styled.div` 
    color: ${prop => prop.theme.colors.primary};
    font-weight: bold;
    font-size: 35px;
`

export const InvalidDeviceSubtitle = styled.div` 
    color: ${prop => prop.theme.colors.gray};
    font-weight: bold;
    font-size: 22px;
`

export const Logo = styled.img`
    width: 200px;
    height: 75px;
    margin-top: 60px;
`

