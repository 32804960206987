import styled from "styled-components";

export const OrdersComponent = styled.div` 
    display: flex;
    flex-direction: column;
    background-color: ${prop => prop.theme.colors.background};
    padding: 20px;
    padding-bottom: 0px;
    height: 100%;
`
export const OrdersBody = styled.div` 
    flex: 1;
    background-color: white;
`