import React from 'react';
import propTypes from 'prop-types';
import { AlertBody, AlertComponent, AlertContainer, AlertMain, AlertText, AlertTitle, ChannelList, } from '../style';
import { useTranslation } from 'react-i18next';

export const EditAlert = ({ show, message }) => {
    const { t } = useTranslation();


    return <AlertComponent show={show} >
        <AlertMain>
            <AlertContainer>
                <AlertBody>
                    <AlertTitle>
                        {message}
                    </AlertTitle>
                    <AlertText>
                        {t('orders.contactUs')}
                    </AlertText>
                    <ChannelList>
                    </ChannelList>
                </AlertBody>
            </AlertContainer>
        </AlertMain>
    </AlertComponent>
}

EditAlert.propTypes = {
    show: propTypes.bool,
    message: propTypes.string,
}



