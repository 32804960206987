import propTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router';
import { StyledLink } from './style';
export default function Link(props) {
    const { storeId, clientId } = useParams();

    const route = storeId && clientId ? `/${storeId}/${clientId}${props.to}` : `${props.to}`;

    return <StyledLink to={route}>{props.children}</StyledLink>;
}

Link.propTypes = {
    to: propTypes.string,
}  
