import React from 'react';
import propTypes from 'prop-types';
import { ModalComponent, ModalMain } from './style';

export default function Modal(props) {
    const { show, top, width, height, onClose, closeButton } = props;

    return <ModalComponent show={show} onClick={!closeButton ? onClose : undefined}>
        <ModalMain top={top} width={width} height={height}>
            {props.children}
        </ModalMain>
    </ModalComponent>
}

Modal.propTypes = {
    show: propTypes.bool,
    onClose: propTypes.func,
    top: propTypes.string,
    width: propTypes.string,
    height: propTypes.string,
    closeButton: propTypes.bool,
}

Modal.defaultProps = {
    top: "20%",
    width: "80%",
    height: "100px",
    closeButton: false,
}


