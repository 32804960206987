import styled from "styled-components";

export const InputContainer = styled.div` 
    -webkit-appearance: none;
    display: inline-flex;
    align-items: center;
    border-radius: 40px;
    border: 1px solid ${prop => prop.theme.colors.graySuperLight};
    background-color: white;
    height: 30px;
    width: 100%;
    overflow: hidden;
    transition: border-color ease 0.5s;

    &:focus-within {
        border-color: ${prop => prop.theme.colors.secondary};
    }

`
export const InputComponent = styled.input`
    border: none;
    outline: none;
    padding: 10px;
    height: 10px;
    width: 100%;
    background-color: white;

    ::placeholder {
        color: ${prop => prop.theme.colors.grayDark};
        opacity: 1;
    }
`
export const InputIcon = styled.div` 
    font-size: 14px;
    padding-left: 15px;
`