import React from 'react';
import ReactSelect from 'react-select';
import { customStyles, SelectContainer, SelectLabel } from './style';
import propTypes from 'prop-types';

const Select = React.forwardRef((props, ref) => {

    const { label, options, value, onChange, ...rest } = props;

    return <SelectContainer>
        <SelectLabel >
            {label}
        </SelectLabel>
        <ReactSelect
            ref={ref}
            value={value}
            onChange={onChange}
            options={options}
            styles={customStyles}
            classNamePrefix="select"
            {...rest}
        />
    </SelectContainer>;
});

Select.propTypes = {
    label: propTypes.string,
    placeholder: propTypes.string,
    options: propTypes.array,
    onChange: propTypes.func,
    value: propTypes.any,
}

export default Select;
