import { useNavigate } from 'react-router';

export const useGoBack = () => {
    const history = useNavigate();

    const goBack = () => {
        history(-1);
    }

    return goBack;
}