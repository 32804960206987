import styled from "styled-components";

export const BadgeItem = styled.div`
position: relative;
`

export const BadgeContainer = styled.div` 
  width: 14px;
  height: 14px;
  padding: 5px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  background-color: ${prop => prop.theme.colors.secondary};  
  color: white;
  position: absolute;
  top: -10px;
  right: -12px;
`