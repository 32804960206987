import React from 'react';
import { ProfileBody, ProfileComponent, ProfileIcon, ProfileItem } from './style';
import PageHeader from '../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { MdOutlineDeliveryDining } from 'react-icons/md';
import { BsCreditCard2Front } from 'react-icons/bs';
import { BiHomeAlt } from 'react-icons/bi';
import { useGoBack } from '../../hooks/useGoBack';

export default function Profile() {
    const { t } = useTranslation();
    const goBack = useGoBack();
    const onlyPickup = true;

    return <ProfileComponent>
        <PageHeader title={t('profile.title')} onClick={goBack} />
        <ProfileBody>
            <ProfileItem>
                <ProfileIcon>
                    <MdOutlineDeliveryDining />
                </ProfileIcon>
                {t('profile.orders')}
            </ProfileItem>
            {!onlyPickup && <><ProfileItem>
                <ProfileIcon>
                    <BsCreditCard2Front />
                </ProfileIcon>
                {t('profile.payments')}
            </ProfileItem>
                <ProfileItem>
                    <ProfileIcon>
                        <BiHomeAlt />
                    </ProfileIcon>
                    {t('profile.addresses')}
                </ProfileItem>
            </>
            }
        </ProfileBody>
    </ProfileComponent>;
}
