import React from 'react'
import { BadgeContainer, BadgeItem } from './style'

export default function Badge(props) {
    const { total } = props;
    return (
        <BadgeItem>
            {total > 0 && <BadgeContainer>{total}</BadgeContainer>}
            {props.children}
        </BadgeItem>
    )
}
