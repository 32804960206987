import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/PageHeader';
import AddressItem from './AddressItem';
import { AddressesAdd, AddressesBody, AddressesComponent } from './style';

export default function Addresses() {
    const { t } = useTranslation();

    return <AddressesComponent>
        <PageHeader title={t('profile.addresses')} />
        <AddressesBody>
            <AddressItem name='Casa' description='Av Rafael Sanzio 771-B Arcos de Guadalupe
                45020 Zapopan, Jal., México'/>
            <AddressItem name='Casa' description='Av Rafael Sanzio 771-B Arcos de Guadalupe
                45020 Zapopan, Jal., México'/>
            <AddressItem name='Casa' description='Av Rafael Sanzio 771-B Arcos de Guadalupe
                45020 Zapopan, Jal., México'/>
            <AddressesAdd>
                {t('addresses.add')}
            </AddressesAdd>
        </AddressesBody>

    </AddressesComponent>;
}
