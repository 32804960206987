import styled from "styled-components";

export const ChannelImage = styled.img`
    height: 20px;
    width: 20px;
    margin-bottom: 10px;
`
export const ChannelLinkContainer = styled.a`
    display: flex;
    text-decoration: none;
`

export const ChannelContainer = styled.div`
    display: flex;
`

export const ChannelName = styled.div` 
    color: ${prop => prop.theme.colors.gray};
    font-weight: bold;
    font-size: 15px;
    margin-left: 10px;
`