import React from 'react'
import propTypes from 'prop-types';
import { ProductPriceContainer, ProductDescription, ProductDetailSimpleContainer, ProductName, ProductPrice } from '../../style';
import formatNumber from '../../../../helpers/formatNumber';


export default function ProductDetailSimple({ name, description, price }) {
    return (<ProductDetailSimpleContainer>
        <ProductName>
            {name}
        </ProductName>
        <ProductDescription>
            {description}
        </ProductDescription>
        <ProductPriceContainer>
            <ProductPrice>
                {formatNumber(price)}
            </ProductPrice>
        </ProductPriceContainer>
    </ProductDetailSimpleContainer>
    )
}

ProductDetailSimple.propTypes = {
    image: propTypes.string,
    name: propTypes.string,
    description: propTypes.string,
    price: propTypes.number,
}
