import React from "react";
import { CategoriesHeader } from "../../components/CategoriesHeader";
import { ProductsPage } from "./ProductsPage";
import { ProductsContainer } from "./style";

export default function ProductsByCategory() {
  return (
    <ProductsContainer>
      <CategoriesHeader />
      <ProductsPage />
    </ProductsContainer>
  );
}
