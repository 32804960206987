import propTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderButton, OrderData, OrderDescription, OrderItemContainer, OrderItemImage, OrderItemInfo, OrderName } from './style';

export default function OrderItem(props) {
    const { t } = useTranslation();

    return <OrderItemContainer>
        <OrderItemImage src={props.image} />
        <OrderItemInfo>
            <div>
                <OrderName>
                    {props.productName}
                </OrderName>
                <OrderDescription>
                    {props.productDescription}
                </OrderDescription>
            </div>
            <OrderData>
                <div>
                    {props.price}
                </div>
                <div>
                    {props.date}
                </div>
            </OrderData>
        </OrderItemInfo>
        <OrderButton>
            {t('orders.orderAgain')}
        </OrderButton>
    </OrderItemContainer>;
}

OrderItem.propTypes = {
    productName: propTypes.string,
    productDescription: propTypes.string,
    price: propTypes.string,
    date: propTypes.string,
    image: propTypes.string,
}