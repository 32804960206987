import { lighten } from "polished";
import styled, { css } from "styled-components";

export const ButtonComponent = styled.button` 
    outline: none;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    border-radius: 40px;
    border: none;
    background-color: ${prop => prop.theme.colors.primary};
    color: white;
    width: ${props => props.width};
    transition: background-color 0.5s;

    &:hover{
      cursor: pointer;
    }   

    ${({ outline }) => outline && css`
         border: 2px solid ${prop => prop.theme.colors.primary};
         background-color: transparent;
         color: ${prop => prop.theme.colors.primary};
      `
  }

    ${({ gray }) => gray && css`
          border-color: ${prop => prop.theme.colors.gray};
          color: ${prop => prop.theme.colors.gray};
        `
  }

    ${({ disabled }) => disabled && css`
      background-color: ${prop => lighten(0.5, prop.theme.colors.primary)};
      pointer-events: none;
        `
  }
`
