import React from 'react';
import truncateWithDots from '../../../helpers/truncateWithDots';
import { CardBody, CardButton, CardInfo, CardSubtitle, CardTitle, CardDelivery, HeaderLogo, HeaderLogoContainer, StoreCard } from '../style';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const StoreInfo = ({ store, onClick, showButton }) => {
    const { t } = useTranslation();

    return <StoreCard onClick={onClick}>
        {showButton && <CardButton />}
        <CardBody>
            <HeaderLogoContainer>
                <HeaderLogo src={store?.imageUrl} />
            </HeaderLogoContainer>
            <CardInfo>
                <CardTitle>
                    {store.name}
                </CardTitle>
                <CardSubtitle>
                    {truncateWithDots(store?.address, 70)}
                </CardSubtitle>
                <CardDelivery>{t('home.deliveryTime', { avg: store.settings?.avgMinutes })}</CardDelivery>
            </CardInfo>
        </CardBody>
    </StoreCard>
        ;
}

StoreInfo.propTypes = {
    store: propTypes.object,
    onClick: propTypes.func,
    showButton: propTypes.bool,
}

StoreInfo.defaultProps = {
    showButton: false,
}


