import propTypes from 'prop-types';
import React from 'react';
import ArrowLeft from '../../assets/icons/arrow-left.svg';
import { ArrowLeftButton, ArrowLeftIcon } from './style';

export const BackButton = ({ round, detail, onClick }) => {

    return (
        <ArrowLeftButton
            round={round}
            detail={detail}
            onClick={onClick}
        >
            <ArrowLeftIcon src={ArrowLeft} round={round} alt="arrowLeft" />
        </ArrowLeftButton>
    )
}

BackButton.propTypes = {
    round: propTypes.bool,
    detail: propTypes.bool,
    onClick: propTypes.func,
}

BackButton.defaultProps = {
    round: true,
    detail: false
}  