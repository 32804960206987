import React from "react";
import { BackButton } from "../../../components/BackButton";
import { useGoBack } from "../../../hooks/useGoBack";
import { object } from "prop-types";
import { useImages } from "../../../context/images";
import ImageGallery from "react-image-gallery";
import "../../../styles/image-gallery.scss";
export default function ProductDetailHeader({ product }) {
  const goBack = useGoBack();
  const { contextImageGallery } = useImages();
  const [images] = contextImageGallery;

  const productImages = product?.imageUrl || [];
  const imagesGallery = productImages.map((original) => ({ original }));
  const selectedImages = images.imgs?.map((original) => ({ original })) || [];

  return (
    productImages.length > 0 && (
      <div>
        <BackButton onClick={goBack} detail={true} />
        <ImageGallery
          items={selectedImages?.length > 0 ? selectedImages : imagesGallery}
          additionalClass="image"
          width="500"
          height="600"
          showThumbnails={false}
          showFullscreenButton={true}
          showPlayButton={false}
          showNav={true}
          autoPlay
          slideDuration={450}
        />
      </div>
    )
  );
}

ProductDetailHeader.propTypes = {
  product: object,
};
