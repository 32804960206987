import { array, func } from "prop-types";
import SearchResult from "../SearchResult";
import { EmptyResults, ResultsContainer } from "./style";
import { MdProductionQuantityLimits } from "react-icons/md";
import {
  EmptyProductsIcon,
  EmptyProductsText,
} from "../../pages/ProductsByCategory/style";
import { useTranslation } from "react-i18next";

const SearchOverlay = ({ items, onSelect }) => {
  const { t } = useTranslation();

  return (
    <ResultsContainer>
      {items?.length > 0 ? (
        items.map((item) => {
          return (
            <SearchResult
              key={item.id}
              item={item}
              onClick={() => onSelect(item)}
            />
          );
        })
      ) : (
        <EmptyResults>
          <EmptyProductsIcon>
            <MdProductionQuantityLimits />
          </EmptyProductsIcon>
          <EmptyProductsText>{t("home.noResults")}</EmptyProductsText>
        </EmptyResults>
      )}
    </ResultsContainer>
  );
};

SearchOverlay.propTypes = {
  items: array,
  onSelect: func,
};

export default SearchOverlay;
