import React, { createContext, useCallback, useContext } from 'react'
import { useState } from 'react';
import { getStore } from '../services/getStore';
import { getCategories } from "../services/categories/getCategories";

export const ShopContext = createContext(null)

export default function ShopProvider({ children }) {
    const [shop, setShop] = useState({ id: null, name: '', imageUrl: '', address: '', description: '' });
    const [payment, setPayment] = useState('');
    const [deliveryEnabled, setDeliveryEnabled] = useState(false);
    const [deliveryOption, setDeliveryOption] = useState(false);
    const [categories, setCategories] = useState([]);

    const loadShop = useCallback((storeId) => {
        getStore(storeId).then((shop) => {
            setShop(shop);

            const { settings, paymentMethod } = shop;
            const onlyCash = settings?.cashPayments && !settings?.cardPayments;
            const bothPaymentMethods = settings?.cashPayments && settings?.cardPayments;
            const paymentsMethods = paymentMethod.map(key => key)
            function paymentDifferent() { 
                if (onlyCash || bothPaymentMethods) {
                    return 'cash'
                }
                return paymentsMethods;
            }
            setPayment( paymentDifferent() );
            setDeliveryEnabled(settings.deliveryEnabled);
        })
    }, [setShop]);

    const loadCategories = useCallback((storeId) => {
        getCategories(storeId).then((categories) => {
            setCategories(categories);
        })
    }, [setCategories]);

    const store = {
        shop: shop,
        categories: categories,
        loadShop: loadShop,
        loadCategories: loadCategories,
        payment: [payment, setPayment],
        deliveryEnabled: deliveryEnabled,
        deliveryOption: [deliveryOption, setDeliveryOption],
    }

    return <ShopContext.Provider value={store}>{children}</ShopContext.Provider>
}

export const useShop = () => {
    return useContext(ShopContext);
}