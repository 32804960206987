import propTypes from 'prop-types';
import React from 'react';
import { ButtonComponent } from './style';

export default function Button(props) {
    return <ButtonComponent {...props}>
        {props.title}
    </ButtonComponent>;
}

Button.propTypes = {
    title: propTypes.string,
    onClick: propTypes.func,
    width: propTypes.string,
    outline: propTypes.bool,
    gray: propTypes.bool,
    disabled: propTypes.bool,
}

Button.defaultProps = {
    width: '100%',
    outline: false,
    gray: false,
    disabled: false,
}  
