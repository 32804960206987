import React, { useState, useEffect } from "react";
import { getAllProducts } from "../../../services/categories/getAllProducts";
import { useParams } from "react-router";
import InfiniteScroll from "react-infinite-scroller";
import EmptyProducts from "../../ProductsByCategory/EmptyProducts";
import Loader from "../../../components/Loader";
import { ProductsPageBody } from "../../ProductsByCategory/style";
import { ProductsByCategoryList } from "../../ProductsByCategory/ProductsList";

export const AllProductsPage = () => {
  const { categoryId } = useParams();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    getAllProducts(categoryId, 1, 25)
      .then((products) => {
        setProducts(products);
        if (products.length === 25) {
          setHasMore(true);
        }
      })
      .finally(() => setLoading(false));
  }, [categoryId]);

  const fetchMoreProducts = () => {
    getAllProducts(categoryId, products.length + 1, products.length + 25).then(
      (result) => {
        setProducts(products.concat(result));
        if (result.length <= 25) {
          setHasMore(false);
        }
      }
    );
  };

  return (
    <ProductsPageBody>
      {loading ? (
        <Loader />
      ) : products.length > 0 ? (
        <InfiniteScroll
          pageStart={1}
          loadMore={fetchMoreProducts}
          hasMore={hasMore}
          loader={<Loader />}
        >
          <ProductsByCategoryList products={products} categoryId={categoryId} />
        </InfiniteScroll>
      ) : (
        <EmptyProducts />
      )}
    </ProductsPageBody>
  );
};
