import styled from "styled-components";
import { darken } from "polished";

export const SubcategoriesBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ multiple }) =>
    multiple ? "space-between" : "flex-start"};
  align-items: center;
  padding: 15px 10px;
  gap: 30px;
`;

export const Subcategory = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  background-color: ${(prop) => prop.theme.colors.grayBackground};
  border: 1px solid;
  border-color: ${(prop) => darken(0.05, prop.theme.colors.graySuperLight)};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.02) 0px 0px 0px 1px;
  border-radius: 4px;
  padding: 10px 20px;
  width: 118px;
  height: 50px;
  word-break: break-word;

  &:hover {
    cursor: pointer;
  }
`;
