import axios from 'axios';

export const getLocationDescription = async (lat, lng) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    const { data } = await axios.get(url);

    let address = '';

    if (data && data.status === 'OK' && data.results.length > 0) {
        address = data.results[0].formatted_address;
    }

    return address;
}
