import styled from "styled-components";

export const ProfileComponent = styled.div` 
    display: flex;
    flex-direction: column;
    background-color: ${prop => prop.theme.colors.background};
    padding: 20px;
    padding-bottom: 0px;
    height: 100%;
`

export const ProfileBody = styled.div` 
    flex: 1;
    background-color: white;
`

export const ProfileItem = styled.div` 
    background-color: white;
    padding: 15px 20px;
    font-weight: 500;
    font-size: 22px;
    border-top: 1px solid ${prop => prop.theme.colors.gray};
    display: flex;
    align-items: center;
    gap: 20px;

    &:last-child {
        border-bottom: 1px solid ${prop => prop.theme.colors.gray};
    }
`
export const ProfileIcon = styled.div` 
   font-size: 35px;
   margin-top: 5px;
`