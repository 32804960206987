import styled from "styled-components";

export const FooterComponent = styled.div` 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-top: 1px solid ${prop => prop.theme.colors.graySuperLight};
    background-color: ${prop => prop.theme.colors.grayLight};
    color: ${prop => prop.theme.colors.alertRed};
    font-weight: bold;
    padding: 0px 10px;
`
