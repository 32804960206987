import styled from "styled-components";

export const DetailCardHeader = styled.div` 
    background-color: ${prop => prop.theme.colors.grayBackground};
    height: 50px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ComponentName = styled.div` 
    font-size: 18px;
    font-weight: bold;
`

export const ComponentInfo = styled.div` 
    display: flex;
    gap: 15px;
`

export const ComponentRequiredWarning = styled.div` 
    font-size: 14px;
    font-weight: bold;
    color: ${prop => prop.theme.colors.alertRed};
`

export const ComponentSingle = styled.div` 
    font-size: 14px;
    color: ${prop => prop.theme.colors.grayDark};
`

export const DetailCardButton = styled.button` 
    outline: none;
    border: none;
    background: ${prop => prop.theme.colors.grayLight};
    vertical-align: middle;
    padding: 8px;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 22px;
    display: flex;
`

export const DetailCardBody = styled.div` 
    background-color: white;
    min-height: 60px;
    padding: 10px;
    display: flex;
    gap: 5px;
    flex-direction: column;
`

export const DetailCardInput = styled.div` 
   margin-top: 30px;
`