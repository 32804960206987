import styled from "styled-components";
import { CardComponent } from "../Card/style";

const ImageCard = styled(CardComponent)` 
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
   
    min-height: ${props => props.width < 390 ? '150px' : '150px'};
    min-width: ${props => props.width < 390 ? '150px' : '165px'};

    display:flex;
    justify-content: center;
    align-items: center;
`
export default ImageCard;