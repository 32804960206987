import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import propTypes from 'prop-types';
import formatNumber from '../../../helpers/formatNumber';
import Modal from "../../../components/Modal";
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { Stock } from "../style";
import { CartModalContainer,
        ScrollTable, 
        ProductOptions, 
        CartModalButton, 
        CartModalBody, 
        InputWrapper, 
        ProductStock } from './style';
import { IoMdClose } from 'react-icons/io';
import Table from 'react-bootstrap/Table';
export default function ProductModal(props) {
    const { t } = useTranslation();
    const { show, onClose,
        handleChangeItem, 
        setInputValue, 
        totalQuantity, 
        wholesalePriceStatus, 
        wholesalePrice, 
        wholesalePriceStatusEdit, 
        stock } = props;
    const [inputValue, setInputVal] = useState();
    const plus = () => {
        handleChangeItem(parseFloat(inputValue));
        onClose();
    }
    const handleChangeValue = (value) => {
        setInputVal(parseFloat(value) || 0);
        setInputValue(parseFloat(value) || 0);
    }
    const disabledNumber = () => {
        return (!inputValue || inputValue > stock || inputValue <= -1);
    }
    return <Modal show={show}>
        <CartModalContainer>
            <CartModalButton onClick={onClose}><IoMdClose /></CartModalButton>
            <CartModalBody>
                {(wholesalePriceStatus && wholesalePriceStatus !== undefined && wholesalePrice.length > 0) || wholesalePriceStatusEdit ?
                <>
                <ScrollTable>
                    <Table align="center">
                    <thead>
                        <tr>
                        <th>{t("products.quantity")}</th>
                        <th>{t("products.unit-price")}</th>
                        </tr>
                    </thead>
                        <tbody>
                        {wholesalePrice?.map(({ _id, minimumAmount, maxAmount, price }) =>
                            <tr key={_id}>
                            <td>{minimumAmount} - {maxAmount}</td>
                            <td>{formatNumber(price)}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    </ScrollTable>
                </>
                :
                <ProductOptions>
                        
                </ProductOptions>
                }
                {
                    <>
                        <InputWrapper>
                            <Input
                                type="number"
                                onInput={(event) => { handleChangeValue(event.target.value) }}
                                label={t("products.enter-the-amount")}
                                autoComplete="off"
                                name="inputValue"
                                item={inputValue}
                            />
                        {inputValue > stock ?
                            <Stock>{t("products.existence", { stock })}</Stock>
                            :
                            <ProductStock>{t("products.existence", { stock: totalQuantity || 0})}</ProductStock>
                        }
                        </InputWrapper>
                    </>
                }
                <Button
                title={t("products.add")}
                onClick={() => plus()}
                disabled={disabledNumber()}
                />
            </CartModalBody>
        </CartModalContainer>
    </Modal>
}

ProductModal.propTypes = {
    inputValue: propTypes.number,
    show: propTypes.bool,
    onClose: propTypes.func,
    onClick: propTypes.func,
    handleChangeItem: propTypes.func,
    setInputValue: propTypes.func,
    initialState: propTypes.object,
}
