import styled from "styled-components"

export const CategoryName = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0px;
`

export const List = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    
    ::-webkit-scrollbar {
        display: none;
    }
`