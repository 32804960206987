import propTypes from 'prop-types';
import React from 'react';
import { CgClose } from 'react-icons/cg';
import { CancelButtonComponent } from './style';

export default function CancelButton({ round, onClick }) {
    return <CancelButtonComponent round={round} onClick={onClick}>
        <CgClose />
    </CancelButtonComponent>;
}

CancelButton.propTypes = {
    round: propTypes.bool,
    onClick: propTypes.func,
}

CancelButton.defaultProps = {
    round: true
}  