import React from 'react'
import propTypes from 'prop-types';
import { TextareaComponent, TextareaContainer, TextareaLabel, TextareaWrapper } from './style'

export default function Textarea(props) {
    const { label, placeholder, value, onChange, cols, rows, ...rest } = props;

    return (
        <TextareaContainer>
            <TextareaLabel>
                {label}
            </TextareaLabel>
            <TextareaWrapper>
                <TextareaComponent
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    cols={cols}
                    rows={rows}
                    {...rest}
                />
            </TextareaWrapper>
        </TextareaContainer >
    )
}

Textarea.propTypes = {
    label: propTypes.string,
    placeholder: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    cols: propTypes.number,
    rows: propTypes.number,
}

Textarea.defaultProps = {
    cols: 20,
    rows: 2,
}